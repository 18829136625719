import { Select, Grid, FormLabel, MenuItem, Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Controller, useFormContext } from "react-hook-form";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import StudentWiseMapping from "./StudentWiseMapping";

function StudentRollOverClassesMap(props) {
  const { control } = useFormContext();
  const {
    index,
    gradesData,
    mappings,
    onMappingChange,
    setValue,
    trigger,
    errors,
    setMappingData,
    remove,
    studentWiseMapping,
    setStudentWiseMapping,
    fields,
  } = props;
  const [selectedGradeFromRollOver, setSelectedGradeFromRollOver] =
    useState(null);
  const [selectedSectionFromRollOver, setSelectedSectionFromRollOver] =
    useState(null);
  const [selectedGradeToRollOver, setSelectedGradeToRollOver] = useState(null);
  const [selectedSectionToRollOver, setSelectedSectionToRollOver] =
    useState(null);
  const [updatedGradesData, setUpdatedGradesData] = useState(null);
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);

  useEffect(() => {
    const filterGrades = (grades, type) => {
      const filteredGrades = {};
      const mappingsFormattedData = {};
      mappings.forEach((mapping, i) => {
        const gradeFrom = mapping[`grade${type}`];
        const sectionFrom = mapping[`section${type}`];
        if (i !== index) {
          if (!mappingsFormattedData[gradeFrom]) {
            mappingsFormattedData[gradeFrom] = [];
          }
          if (
            sectionFrom &&
            !mappingsFormattedData[gradeFrom].includes(sectionFrom)
          ) {
            mappingsFormattedData[gradeFrom].push(sectionFrom);
          }
        }
      });

      for (let grade in grades) {
        const filteredSection = grades[grade].filter(
          (section) =>
            !mappingsFormattedData.hasOwnProperty(grade) ||
            !mappingsFormattedData[grade].includes(section)
        );
        if (filteredSection.length > 0) {
          filteredGrades[grade] = filteredSection;
        }
      }

      return filteredGrades;
    };

    setUpdatedGradesData({
      gradesFromRollOver: filterGrades(
        gradesData["gradesFromRollOver"],
        "From"
      ),
      gradesToRollOver: filterGrades(gradesData["gradesToRollOver"], "To"),
    });
    setIsAllFieldsFilled(
      !!selectedGradeFromRollOver &&
        !!selectedSectionFromRollOver &&
        !!selectedGradeToRollOver &&
        !!selectedSectionToRollOver
    );
  }, [mappings]);

  const handleGradeFromChange = (e) => {
    const updatedGradeFromRollOver = e.target.value;
    const updatedGradeToRollOver = String(
      parseInt(updatedGradeFromRollOver) + 1
    );
    setSelectedGradeFromRollOver(updatedGradeFromRollOver);
    setSelectedGradeToRollOver(updatedGradeToRollOver);
    setSelectedSectionFromRollOver(null);
    setSelectedSectionToRollOver(null);
    setStudentWiseMapping(false);
    setValue(`mappings.${index}.gradeFrom`, updatedGradeFromRollOver);
    setValue(`mappings.${index}.sectionFrom`, "");
    setValue(`mappings.${index}.gradeTo`, updatedGradeToRollOver);
    setValue(`mappings.${index}.sectionTo`, "");
    onMappingChange(index, {
      gradeFrom: updatedGradeFromRollOver,
      sectionFrom: null,
      gradeTo: updatedGradeToRollOver,
      sectionTo: null,
    });
    trigger();
  };

  const handleSectionFromChange = (e) => {
    const changedSection = e.target.value;
    setSelectedSectionFromRollOver(changedSection);
    setValue(`mappings.${index}.sectionFrom`, changedSection);
    onMappingChange(index, {
      gradeFrom: selectedGradeFromRollOver,
      sectionFrom: changedSection,
      gradeTo: selectedGradeToRollOver,
      sectionTo: selectedSectionToRollOver,
    });
    trigger();
  };

  const handleGradeToChange = (e) => {
    setSelectedGradeToRollOver(e.target.value);
    setValue(`mappings.${index}.GradeTo`, e.target.value);
    onMappingChange(index, {
      gradeFrom: selectedGradeFromRollOver,
      sectionFrom: selectedSectionFromRollOver,
      gradeTo: e.target.value,
      sectionTo: null,
    });
    trigger();
  };

  const handleSectionToChange = (e) => {
    const changedSection = e.target.value;
    setSelectedSectionToRollOver(changedSection);
    setValue(`mappings.${index}.sectionTo`, changedSection);
    onMappingChange(index, {
      gradeFrom: selectedGradeFromRollOver,
      sectionFrom: selectedSectionFromRollOver,
      gradeTo: selectedGradeToRollOver,
      sectionTo: changedSection,
    });
    trigger();
  };

  const handleStudentWiseMappingChange = (e) => {
    setValue(`mappings.${index}.studentWiseMapping`, e.target.checked);
    if (!e.target.checked) {
      setValue(`mappings.${index}.studentList`, "");
    }
    setStudentWiseMapping(e.target.checked);
  };

  const updateStudentListInFormMappings = (list) => {
    setValue(`mappings.${index}.studentList`, list);
  };

  return (
    <Grid>
      <Grid container spacing={3} style={{ paddingTop: 10 }}>
        <Grid item md={2}>
          <Controller
            control={control}
            name={`mappings.${index}.gradeFrom`}
            render={({
              field: { value, onChange, ...field },
              fieldState: { error },
            }) => (
              <>
                <FormLabel>Grade</FormLabel>
                <Select
                  {...field}
                  ref={field.ref}
                  SelectDisplayProps={{
                    style: { paddingTop: 10, paddingBottom: 10 },
                  }}
                  value={selectedGradeFromRollOver || ""}
                  onChange={handleGradeFromChange}
                  style={{ width: "100%" }}
                >
                  {updatedGradesData &&
                    Object.keys(updatedGradesData["gradesFromRollOver"]).map(
                      (grade) => {
                        return (
                          <MenuItem key={grade} value={grade}>
                            {grade}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
                {error && (
                  <span
                    style={{
                      marginTop: -15,
                      marginBottom: 10,
                      color: "red",
                    }}
                  >
                    {error?.message}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item md={3}>
          <Controller
            control={control}
            name={`mappings.${index}.sectionFrom`}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormLabel>Section</FormLabel>
                <Select
                  {...field}
                  ref={field.ref}
                  name={`mappings.${index}.sectionFrom`}
                  SelectDisplayProps={{
                    style: { paddingTop: 10, paddingBottom: 10 },
                  }}
                  value={selectedSectionFromRollOver || ""}
                  onChange={handleSectionFromChange}
                  style={{ width: "100%" }}
                >
                  {selectedGradeFromRollOver &&
                    updatedGradesData["gradesFromRollOver"][
                      selectedGradeFromRollOver
                    ]?.map((section) => {
                      return (
                        <MenuItem key={section} value={section}>
                          {section}
                        </MenuItem>
                      );
                    })}
                </Select>
                {error && (
                  <span
                    style={{
                      marginTop: -15,
                      marginBottom: 10,
                      color: "red",
                    }}
                  >
                    {error?.message}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid
          item
          md={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid item md={1} style={{ flex: 0.5 }}></Grid>
          <DoubleArrowIcon />
          {errors?.mappings?.[index] && (
            <Grid item md={1} style={{ flex: 0.5 }}></Grid>
          )}
        </Grid>
        <Grid item md={2}>
          <Controller
            control={control}
            name={`mappings.${index}.gradeTo`}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormLabel>Grade</FormLabel>
                <Select
                  SelectDisplayProps={{
                    style: { paddingTop: 10, paddingBottom: 10 },
                  }}
                  value={selectedGradeToRollOver || ""}
                  disabled={selectedGradeFromRollOver}
                  onChange={handleGradeToChange}
                  style={{ width: "100%" }}
                >
                  {updatedGradesData &&
                    selectedGradeToRollOver &&
                    !Object.keys(
                      updatedGradesData["gradesToRollOver"]
                    ).includes(selectedGradeToRollOver) && (
                      <MenuItem
                        key={selectedGradeToRollOver}
                        value={selectedGradeToRollOver}
                      >
                        {selectedGradeToRollOver}
                      </MenuItem>
                    )}
                  {updatedGradesData &&
                    Object.keys(updatedGradesData["gradesToRollOver"]).map(
                      (grade) => {
                        return (
                          <MenuItem key={grade} value={grade}>
                            {grade}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
                {error && (
                  <span
                    style={{
                      marginTop: -15,
                      marginBottom: 10,
                      color: "red",
                    }}
                  >
                    {error?.message}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item md={3}>
          <Controller
            control={control}
            name={`mappings.${index}.sectionTo`}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormLabel>Section</FormLabel>
                <Select
                  {...field}
                  ref={field.ref}
                  SelectDisplayProps={{
                    style: { paddingTop: 10, paddingBottom: 10 },
                  }}
                  value={selectedSectionToRollOver || ""}
                  onChange={handleSectionToChange}
                  style={{ width: "100%" }}
                >
                  {selectedGradeToRollOver &&
                    updatedGradesData["gradesToRollOver"][
                      selectedGradeToRollOver
                    ]?.map((section) => {
                      return (
                        <MenuItem key={section} value={section}>
                          {section}
                        </MenuItem>
                      );
                    })}
                </Select>
                {error && (
                  <span
                    style={{
                      marginTop: -15,
                      marginBottom: 10,
                      color: "red",
                    }}
                  >
                    {error?.message}
                  </span>
                )}
              </>
            )}
          />
        </Grid>
        <Grid
          item
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid item md={1} style={{ flex: 0.5 }}></Grid>
          <RemoveCircleIcon
            style={{ cursor: "pointer" }}
            type="button"
            onClick={() => {
              setMappingData((mappingsData) => {
                const newData = [...mappingsData];
                newData.splice(index, 1);
                return newData;
              });
              remove(index);
            }}
          />
          {errors?.mappings?.[index] && (
            <Grid item md={1} style={{ flex: 0.5 }}></Grid>
          )}
        </Grid>
      </Grid>
      {fields.length == 1 && (
        <Grid container style={{ paddingTop: 10 }}>
          <Checkbox
            checked={studentWiseMapping}
            onChange={handleStudentWiseMappingChange}
            disabled={!isAllFieldsFilled}
            sx={{
              padding: 0,
              margin: 0,
            }}
          />
          <span
            style={{
              color: isAllFieldsFilled ? "inherit" : "grey",
            }}
          >
            Mapping At Student Level
          </span>
        </Grid>
      )}
      <Grid>
        {studentWiseMapping && (
          <StudentWiseMapping
            grade={selectedGradeFromRollOver}
            section={selectedSectionFromRollOver}
            gradeMoveTo={selectedGradeToRollOver}
            sectionMoveTo={selectedSectionToRollOver}
            updateStudentListInFormMappings={updateStudentListInFormMappings}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default StudentRollOverClassesMap;
