import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvatarGrade2 from "../../Assets/Images/AvatarBlackGrade2.svg";
import AvatarGrade3 from "../../Assets/Images/AvatarBlackGrade3.svg";
import AvatarGrade4 from "../../Assets/Images/AvatarBlackGrade4.svg";
import AvatarGrade5 from "../../Assets/Images/AvatarBlackGrade5.svg";
import AvatarGrade6 from "../../Assets/Images/AvatarBlackGrade6.svg";
import AvatarGrade7 from "../../Assets/Images/AvatarBlackGrade7.svg";
import AvatarGrade8 from "../../Assets/Images/AvatarBlackGrade8.svg";
import { authUserContext } from "../../Components/Contexts/AuthUser";
import TeacherReportsDownloadPopup from "../../Components/Downloader/TeacherReportsDownloadPopup";
import { PrimaryNavBarContext } from "../../Components/Layout/Layout";
import Loader from "../../Components/Loader/Loader";
import Tooltip from "../../Components/Tooltips/Tooltip";
import { downloadFile } from "../../features/FilesDownloader/FilesDownloader";
import { setClassesData } from "../../features/TeacherUserData/ClassesData";
import "../../Styles/Classes.css";
import "../../Styles/Global.css";

const avatarGradeMapper = {
  1: AvatarGrade2,
  2: AvatarGrade2,
  3: AvatarGrade3,
  4: AvatarGrade4,
  5: AvatarGrade5,
  6: AvatarGrade6,
  7: AvatarGrade7,
  8: AvatarGrade8,
};
function TeacherClasses() {
  document.title = "CSSoch | Classes";
  const [classes, setClasses] = useState(
    useSelector((state) => state.classesData.classesData)
  );
  const {
    setIsAdminDashboardOrTeacherClassess,
    setIsTeacherDashboard,
    isTeacherClassesPage,
    setIsTeacherClassesPage,
  } = useContext(PrimaryNavBarContext);

  const dispatch = useDispatch();
  const [grades, setGrades] = useState({});
  const [classSectionAssignment, setClassSectionAssignment] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(authUserContext);
  const disableDownloads = useSelector(
    (state) => state?.fileDownloader?.disableButtonKeys
  );
  const [reportDownloadData, setReportDownloadData] = useState({});
  const [showReportsDownloadPopup, setShowReportsDownloadPopup] =
    useState(false);
  const [hideStudentReportDownload, setHideStudentReportDownload] =
    useState(true);

  useEffect(() => {
    function fetchClasesDetails() {
      setIsAdminDashboardOrTeacherClassess(true);
      setIsTeacherDashboard(false);
      setIsTeacherClassesPage(true);
      if (classes && Object.keys(classes).length) {
        updateClassesData(classes);
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      };
      fetch(`${process.env.REACT_APP_API_BASE_URL}/classes`, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          dispatch(setClassesData(data));
          updateClassesData(data);
        });
    }
    fetchClasesDetails();

    return () => {
      setIsTeacherClassesPage(false);
    };
  }, [isTeacherClassesPage]);

  useEffect(() => {
    const fetchDownloadReportStatus = async () => {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/check-download-report-status`
      )
        .then((res) => {
          if (res.redirected === true) {
            document.location = "/landing";
          }
          return res.json();
        })
        .then((data) => {
          setHideStudentReportDownload(!data.status);
        });
    };
    fetchDownloadReportStatus();
  }, []);

  const updateClassesData = (data) => {
    setClasses(data);
    setClassSectionAssignment(data.classes);
    setGrades(data.grades);
    setIsLoading(false);
  };

  const downloadProgressReport = (grade) => {
    const schoolCode = user.schoolcode;
    const fileDesc = {
      title: `Class Report Grade ${grade}`,
      waitingMessage: "initializing...",
      url: `${process.env.REACT_APP_API_BASE_URL}/class-report/${grade}`,
      method: "GET",
      body: {},
      filename: `CSSoch_Class_Report_${grade}_${schoolCode}.xlsx`,
    };
    dispatch(downloadFile(fileDesc));
  };

  const handleStudentReportCardDownload = (e, grade, section) => {
    e.stopPropagation();
    setReportDownloadData({
      grade,
      section,
      key: `report-btn-${grade}-${section}`,
    });
    setShowReportsDownloadPopup(true);
  };

  const isDownloadStudentReportDisabled = (grade, section) => {
    return disableDownloads.includes(`report-btn-${grade}-${section}`);
  };

  if (isLoading) {
    return (
      <div className="classes-container">
        <Loader />
      </div>
    );
  }

  return (
    <div className="classes-container">
      <h1>Assigned Classes</h1>
      <hr />
      <div className="classes-selection">
        {grades &&
          Object.keys(grades)
            .sort()
            .map((grade, gradeIndex) => (
              <>
                <div
                  className="class-name-row"
                  style={{ marginTop: gradeIndex ? "2vw" : "1vw" }}
                >
                  <div className="class-name-container">
                    <h2 key={`grade-title-${grade}`}>Grade {grade}</h2>
                    <Tooltip
                      key={`grade-report-${grade}`}
                      title={"Download Class Report"}
                      placement="top"
                    >
                      <button
                        className="download-class-report-btn"
                        onClick={() => {
                          downloadProgressReport(grade);
                        }}
                      >
                        {" "}
                        <FileDownloadIcon />{" "}
                      </button>
                    </Tooltip>
                  </div>

                  <span className="d-flex">
                    <button
                      className="view-community"
                      id="view-community2"
                      onClick={() => {
                        setIsAdminDashboardOrTeacherClassess(false);
                        navigate(`/community/${grade}`);
                      }}
                    >
                      <span className="community-text"> Community </span>
                    </button>
                    <button
                      className="view-analytics"
                      id="view-analytics2"
                      onClick={() =>
                        navigate(`/admin/dashboard?grade=${grade}`)
                      }
                    >
                      <img
                        className="analytics-icon"
                        src="../static/svg/TeacherAnalytics.svg"
                        alt=""
                      />
                      <span className="analytics-text">Analytics</span>
                    </button>
                  </span>
                </div>
                {[...grades[grade]].sort().map((section) => (
                  <div
                    key={section}
                    className="class-box"
                    onClick={() => {
                      setIsAdminDashboardOrTeacherClassess(false);
                      navigate(`/dashboard/${grade}/${section}`);
                    }}
                  >
                    <p className="d-flex">
                      <img
                        src={avatarGradeMapper[grade]}
                        alt="avatar"
                        style={{
                          width:
                            grade === "5" || grade === "3" || grade === "6"
                              ? "2.3vw"
                              : "1.5vw",
                        }}
                      />{" "}
                      Class {section}
                    </p>
                    <div className="assignment-box">
                      {!hideStudentReportDownload && (
                        <Tooltip
                          title={"Download in progress"}
                          placement="top"
                          sx={
                            isDownloadStudentReportDisabled(grade, section)
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <span onClick={(e) => e.stopPropagation()}>
                            <button
                              className="download-report-cards-btn"
                              onClick={(e) =>
                                handleStudentReportCardDownload(
                                  e,
                                  grade,
                                  section
                                )
                              }
                              disabled={isDownloadStudentReportDisabled(
                                grade,
                                section
                              )}
                            >
                              {" "}
                              Report Card
                              <FileDownloadIcon />
                            </button>
                          </span>
                        </Tooltip>
                      )}
                      {classSectionAssignment[grade][section].length &&
                        classSectionAssignment[grade][section].map(
                          (assignment, index) => (
                            <>
                              {index !== 0 ? (
                                <img
                                  className="bar"
                                  src="../static/svg/HorizontalBarTeacher.svg"
                                  alt=""
                                />
                              ) : (
                                <></>
                              )}
                              {assignment === "assigned" ? (
                                <img
                                  className="icon"
                                  src="../static/svg/AssignedClassesIcon.svg"
                                  alt=""
                                />
                              ) : (
                                <img
                                  className="icon"
                                  src="../static/svg/NotassignedClassesIcon.svg"
                                  alt=""
                                />
                              )}
                            </>
                          )
                        )}
                    </div>
                  </div>
                ))}
              </>
            ))}
        <h2 style={{ display: "none" }}> No Classes is Assigned Yet</h2>
      </div>
      {showReportsDownloadPopup && (
        <TeacherReportsDownloadPopup
          grade={reportDownloadData.grade}
          section={reportDownloadData.section}
          reportKey={reportDownloadData.key}
          setShowReportsDownloadPopup={setShowReportsDownloadPopup}
        />
      )}
    </div>
  );
}

export default TeacherClasses;
