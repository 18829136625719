import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import React, { memo } from "react";

const dialogBoxStyle = {
  "& .MuiPaper-root": {
    fontFamily: "rajdhani-medium",
    border: "3px solid #25A596",
    borderRadius: "4vw",
    maxWidth: "60vw",
    width: "60vw",
    height: "fit-content",
    padding: "1rem",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255,255,255,0.85)",
  },
  "& #customized-dialog-title": {
    display: "flex",
    justifyContent: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "5vw",
    margin: 0,
    padding: 0,
    marginBottom: "3.2vh",
    lineHeight: "5vw",
    fontWeight: "normal",
    marginTop: "1vw",
  },
  "& .MuiPaper-root .MuiTypography-gutterBottom": {
    textAlign: "center",
    fontFamily: "rajdhani-medium",
    fontSize: "2vw",
    marginTop: 0,
    marginBottom: "1rem",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "center",
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root Button": {
    fontFamily: "rajdhani-medium",
    color: "black",
    backgroundColor: "#FFB017",
    border: "none",
    borderRadius: "10px",
    padding: "1px 20px",
    fontSize: "1.5vw",
    transition: "0.5s",
    textTransform: "none",
    margin: 0,
    width: "5vw",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "0.5s",
    },
  },
  "& #change-password-action-buttons": {
    display: "flex",
    justifyContent: "space-between",
    width: "13vw",
  },
  "& .MuiButtonBase-root:disabled": {
    color: "rgba(16, 16, 16, 0.3)",
    cursor: "not-allowed",
  },
};

const BootstrapDialog = styled(Dialog)(
  ({ theme, customstyle }) => customstyle || dialogBoxStyle
);

const PopupBox = memo(function PopupBox(props) {
  const { children, handleClose, open, customDialogStyle } = props;

  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      customstyle={customDialogStyle}
    >
      {children}
    </BootstrapDialog>
  );
});

export default PopupBox;
