import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CircularProgress, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile } from "../../features/FilesDownloader/FilesDownloader";
import "../../Styles/Progress.css";
import { authUserContext } from "../Contexts/AuthUser";
import AssesmentProgress from "./Progress/AssesmentProgress";
import ExerciseProgress from "./Progress/ExerciseProgress";
import Feedback from "./Progress/Feedback";
import ModuleCompletion from "./Progress/ModuleCompletion";
import ModuleProgressBar from "./Progress/ModuleProgressBar";
import RecentRewards from "./Progress/RecentRewards";
import Rewards from "./Progress/Rewards";
import SkillProfile from "./Progress/SkillProfile";

const startSvg = (
  <svg viewBox="0 0 84 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.86563 36.6017L2.8657 36.6018L18.8512 48.1406L18.8513 48.1407C20.5023 49.3319 21.1924 51.4418 20.564 53.3671L14.4546 72.042L14.4545 72.0422C12.7418 77.2842 18.7759 81.6173 23.2454 78.3937L23.2456 78.3935L39.2311 66.8528L39.2312 66.8527C40.883 65.6594 43.118 65.6594 44.7697 66.8527L44.7698 66.8528L60.7553 78.3935L60.7554 78.3936C65.2232 81.6174 71.2591 77.2841 69.5464 72.0422L69.5463 72.0421L63.4396 53.3668L63.4394 53.3664C62.8078 51.4416 63.4976 49.3321 65.1504 48.1408L65.1507 48.1406L81.1343 36.6017L81.1344 36.6017C85.6162 33.3644 83.3074 26.3276 77.7796 26.3276H58.0204C55.9777 26.3276 54.1659 25.0197 53.5383 23.0945L53.5382 23.0941L47.4332 4.42524C47.4332 4.42522 47.4332 4.4252 47.4332 4.42518C45.7224 -0.80868 38.2803 -0.808072 36.5677 4.42508L37.0429 4.58059L36.5677 4.4251L30.4591 23.0949L30.459 23.0954C29.8312 25.0211 28.0262 26.3285 25.9805 26.3285H6.22035C0.692765 26.3285 -1.61639 33.3653 2.86563 36.6017Z"
      fill="#FFB017"
    />
  </svg>
);

export default function Progress(props) {
  document.title = "CSSoch | Progress";
  const dispatch = useDispatch();
  const { user } = useContext(authUserContext);
  const { moduleStatusData, section, grade } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isAssesmentCompleted, setIsAssesmentCompleted] = useState(true);
  const [overallProgress, setOverallProgress] = useState([]);
  const [progressData, setProgressData] = useState();
  const [feedbackData, setFeedbackData] = useState();
  const [currentModuleNum, setCurrentModuleNum] = useState(-1);
  const [feedbackStars, setFeedbackStar] = useState([]);
  const [assesmentGameReportData, setAssesmentGameReportData] = useState([]);
  const [assesmentPuzzleReportData, setAssesmentPuzzleReportData] = useState(
    []
  );
  const [assesmentPythonReportData, setAssesmentPythonReportData] = useState(
    []
  );
  const [exerciseData, setExerciseData] = useState([]);
  const [assesmentScore, setAssesmentScore] = useState([0, 0]);
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [noOfExerciseComponentsInModule, setNoOfExerciseComponentsInModule] =
    useState(2);
  const [moduleData, setModuleData] = useState();
  const disableDownloads = useSelector(
    (state) => state?.fileDownloader?.disableButtonKeys
  );
  const [hideDownloadButtons, setHideDownloadButtons] = useState(true);

  useEffect(() => {
    const fetchDownloadReportStatus = async () => {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/check-download-report-status`
      )
        .then((res) => {
          if (res.redirected === true) {
            document.location = "/landing";
          }
          return res.json();
        })
        .then((data) => {
          setHideDownloadButtons(!data.status);
        });
    };
    fetchDownloadReportStatus();
  }, []);

  useEffect(() => {
    if (moduleStatusData && section) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/progress`)
        .then((res) => {
          if (res.redirected === true) {
            document.location = "/landing";
          }
          return res.json();
        })
        .then((data) => {
          setProgressData(data.module_progress_data);
          setFeedbackData(data.feedback);
          setOverallProgress(data.overall_progress);
        });
    }
  }, [moduleStatusData, section]);

  useEffect(() => {
    if (moduleStatusData) {
      const moduleStusDataCopy = JSON.parse(JSON.stringify(moduleStatusData));
      setModuleData(moduleStusDataCopy);
    }
  }, [moduleStatusData]);

  useEffect(() => {
    if (feedbackData && progressData && currentModuleNum >= 0) {
      if (moduleData?.statuses[currentModuleNum] !== "locked") {
        const stars = [];
        if (feedbackData[currentModuleNum] > 0) {
          setIsFeedbackSubmitted(true);
        } else {
          setIsFeedbackSubmitted(false);
        }
        for (let i = 1; i <= 5; i++) {
          if (i <= feedbackData[currentModuleNum]) {
            stars.push(1);
          } else {
            stars.push(0);
          }
        }
        setFeedbackStar(stars);
        setExerciseData(progressData[currentModuleNum]["exercise"]);
        const noOfComponents = Object.keys(
          progressData[currentModuleNum]["exercise"]
        ).length;
        setNoOfExerciseComponentsInModule(noOfComponents);
        if (progressData[currentModuleNum]["assessment"] !== false) {
          setIsAssesmentCompleted(true);
        } else {
          setIsAssesmentCompleted(false);
          return;
        }
        const assesmentGameScore = [];
        const assesmentPuzzleScore = [];
        const assesmentPythonScore = [];
        const assesmentTotalScore = [0, 0];
        let gameIndex = 1;
        let puzzleIndex = 1;
        let pytonIndex = 1;
        progressData[currentModuleNum]["assessment"].map((item, index) => {
          if (item.type === "game" || item.type === "python") {
            let score = 5;
            let attr = "good";
            let result = "Correct";
            if (item.giveup === 1) {
              attr = "bad";
              result = "Gave Up";
              score = 0;
            }
            if (item?.type === "python") {
              assesmentPythonScore.push({
                num: pytonIndex,
                attempts: item.attempts,
                attr,
                result,
                score,
              });
              assesmentTotalScore[0] += score;
              assesmentTotalScore[1] += 5;
              pytonIndex++;
            } else {
              assesmentGameScore.push({
                num: gameIndex,
                attempts: item.attempts,
                attr,
                result,
                score,
              });
              assesmentTotalScore[0] += score;
              assesmentTotalScore[1] += 5;
              gameIndex++;
            }
          } else {
            let attr = "good";
            let result = "Correct";
            let score = 1;
            if (item.giveup === 1) {
              attr = "bad";
              result = "Gave Up";
              score = 0;
            } else if (item.result === 0) {
              attr = "bad";
              result = "Incorrect";
              score = 0;
            }
            assesmentPuzzleScore.push({
              num: puzzleIndex,
              attr,
              result,
              score,
            });
            assesmentTotalScore[0] += score;
            assesmentTotalScore[1] += 1;
            puzzleIndex++;
          }
        });
        setAssesmentScore(assesmentTotalScore);
        setAssesmentGameReportData(assesmentGameScore);
        setAssesmentPuzzleReportData(assesmentPuzzleScore);
        setAssesmentPythonReportData(assesmentPythonScore);
      }
      setIsLoading(false);
    } else if (progressData && feedbackData) {
      setIsLoading(false);
    }
  }, [currentModuleNum, progressData, feedbackData]);

  const handleReportDownload = () => {
    const schoolCode = user.schoolcode;
    const studentNameArray = user.fullname.split(" ");
    const studentName = studentNameArray
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("_");

    const fileDesc = {
      title: `Report Card`,
      waitingMessage: "initializing...",
      url: `${process.env.REACT_APP_API_BASE_URL}/student-report/${grade}/${section}`,
      method: "GET",
      body: {},
      filename: `CSSoch_${studentName}_Report_Card.pdf`,
      key: `student-report-${grade}-${section}`,
    };
    dispatch(downloadFile(fileDesc));
  };

  const handleCertificateDownload = () => {
    const studentNameArray = user.fullname.split(" ");
    const studentName = studentNameArray
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("_");

    const fileDesc = {
      title: `Certificate`,
      waitingMessage: "initializing...",
      url: `${process.env.REACT_APP_API_BASE_URL}/certificate`,
      method: "GET",
      body: {},
      filename: `CSSoch_${studentName}_Certificate.pdf`,
      key: `student-certificate-${grade}-${section}`,
    };
    dispatch(downloadFile(fileDesc));
  };

  return (
    <div className="progress-content">
      {!isLoading ? (
        <>
          <ModuleProgressBar
            currentModuleIndex={currentModuleNum}
            setCurrentModuleIndex={setCurrentModuleNum}
            moduleStatusData={moduleData}
          />
          {moduleData && moduleData?.statuses[currentModuleNum] !== "locked" ? (
            <>
              {currentModuleNum >= 0 ? (
                <>
                  <div className="progress-module-header-container">
                    <p className="progress-module-header">
                      Module
                      {` ${currentModuleNum + 1}`}:
                      {` ${moduleData?.names[currentModuleNum]}`}
                    </p>
                    <p
                      className="back-to-overall"
                      onClick={() => setCurrentModuleNum(-1)}
                    >
                      <u> Back to Overall Progress</u>
                    </p>
                  </div>
                  <Grid container className="progress-content-container">
                    <Grid
                      container
                      item
                      xs={12}
                      className="progress-exercise-assesment"
                    >
                      <Grid
                        item
                        xs={noOfExerciseComponentsInModule > 2 ? 6.75 : 5.75}
                        className="progress-exercise-container"
                      >
                        <Grid container item className="progress-exercise">
                          <ExerciseProgress
                            exerciseData={exerciseData}
                            noOfExerciseComponentsInModule={
                              noOfExerciseComponentsInModule
                            }
                          />
                        </Grid>
                        <Grid className="demolevel-label">
                          <p>DL: Demo Level</p>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={noOfExerciseComponentsInModule > 2 ? 4.75 : 5.75}
                        className="progress-assesment"
                      >
                        <AssesmentProgress
                          assesmentGameReportData={assesmentGameReportData}
                          isAssesmentCompleted={isAssesmentCompleted}
                          assesmentPuzzleReportData={assesmentPuzzleReportData}
                          assesmentPythonReportData={assesmentPythonReportData}
                          assesmentScore={assesmentScore}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      className="progress-feedback-rewards"
                    >
                      <Grid item xs={5.75} className="progress-feedback">
                        <Feedback
                          isFeedbackSubmitted={isFeedbackSubmitted}
                          feedbackStars={feedbackStars}
                        />
                      </Grid>
                      <Grid item xs={5.75} className="progress-rewards">
                        <Rewards />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                overallProgress && (
                  <>
                    <div className="progress-module-header-container">
                      <p className="overall-progress-module-header">
                        Overall Progress{" "}
                      </p>
                      {!hideDownloadButtons && (
                        <div className="progress-downloads-btn-container">
                          <button
                            className="certificate-download-btn"
                            disabled={disableDownloads.includes(
                              `student-certificate-${grade}-${section}`
                            )}
                            onClick={handleCertificateDownload}
                          >
                            Certificate
                            {startSvg}
                          </button>
                          <button
                            className="overall-pogress-download-btn"
                            disabled={disableDownloads.includes(
                              `student-report-${grade}-${section}`
                            )}
                            onClick={handleReportDownload}
                          >
                            Report Card
                            <FileDownloadIcon />
                          </button>
                        </div>
                      )}
                    </div>
                    <Grid
                      container
                      className="overall-progress-content-container"
                    >
                      <Grid
                        container
                        item
                        xs={12}
                        className="module-completion-container"
                      >
                        <Grid
                          container
                          item
                          xs={11.75}
                          className="module-completion-progress"
                        >
                          <ModuleCompletion
                            module_completion_data={
                              overallProgress.module_completion_data
                            }
                            isPython={overallProgress.isPython}
                            moduleData={moduleData}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className="skill-profile-recent-rewards"
                      >
                        <Grid item xs={7.5} className="skills-profile">
                          <SkillProfile
                            skills_acuired={overallProgress.skills_acuired}
                          />
                        </Grid>
                        <Grid item xs={4} className="recent-rewards">
                          <RecentRewards />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              )}
            </>
          ) : (
            <div className="progress-locked-box">
              <p>Module is not assigned yet</p>
            </div>
          )}
        </>
      ) : (
        <div className="progress-loading-box">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
