import { yupResolver } from "@hookform/resolvers/yup";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import * as yup from "yup";
import Loader from "../../Components/Loader/Loader";
import StudentRollOverClassesMap from "../../Components/StudentRollOver/StudentRollOverClassesMap";
import ConfirmationDialogBox from "../../Components/Dialogs/ConfirmationDialogBox";

let initial = {
  gradeFrom: "",
  sectionFrom: "",
  gradeTo: "",
  sectionTo: "",
  studentWiseMapping: false,
};
let defaultValues = {
  mappings: [],
};

const requiredFieldText = "This is required field";
const schema = yup.object({
  mappings: yup.array().of(
    yup.object({
      gradeFrom: yup.string().required(requiredFieldText),
      sectionFrom: yup.string().required(requiredFieldText),
      gradeTo: yup.string().required(requiredFieldText),
      sectionTo: yup.string().required(requiredFieldText),
    })
  ),
});

function StudentRollOverMapping() {
  document.title = "CSSoch | Student Rollover";
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappings",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [gradesData, setGradesData] = useState(
    location?.state?.rollOverClassesData
  );
  const [mappingData, setMappingData] = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(true);
  const [noClassToRollOver, setNoClassToRollOver] = useState(false);
  const [isFieldPresent, setIsFieldPresent] = useState(false);
  const [studentWiseMapping, setStudentWiseMapping] = useState(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [formDataToSubmit, setFormDataToSubmit] = useState(null);

  useEffect(() => {
    const intialLoading = async () => {
      if (
        Object.keys(gradesData["gradesFromRollOver"]).length === 0 ||
        Object.keys(gradesData["gradesToRollOver"]).length === 0
      ) {
        setNoClassToRollOver(true);
      }
      append(initial);
      setIsLoading(false);
    };

    if (gradesData) {
      intialLoading();
    }
  }, [gradesData]);

  useEffect(() => {
    if (!fields?.length) {
      setIsFieldPresent(false);
    } else setIsFieldPresent(true);
    if (!mappingData?.length) {
      setDisableSaveBtn(true);
    } else setDisableSaveBtn(false);
  }, [mappingData, fields]);

  const handleMappingChange = (index, mapping) => {
    const newMappings = [...mappingData];
    newMappings[index] = mapping;
    setMappingData(newMappings);
  };

  const handleFormSubmit = (data, e) => {
    setFormDataToSubmit(data);
    setDisableSaveBtn(true);
    setShowConfirmationBox(true);
  };

  const confirmSubmit = () => {
    setShowConfirmationBox(false);
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formDataToSubmit),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/student/rollover`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          navigate("/student/list");
        }
        setIsLoading(false);
      });
  };

  const closeConfirmationBox = () => {
    setShowConfirmationBox(false);
    setDisableSaveBtn(false);
    setIsLoading(false);
    setFormDataToSubmit(null);
  };

  return (
    <div style={{ backgroundColor: "#DDE9F5", height: "100%" }}>
      <Loader style={isLoading ? {} : { display: "none" }}></Loader>
      <Container>
        <Typography variant="h6" component="h2" style={{ padding: 10 }}>
          Student Roll Over
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            marginTop: 5,
            padding: 20,
            borderRadius: 6,
          }}
        >
          {noClassToRollOver ? (
            <>
              <Typography
                variant="h6"
                component="h2"
                style={{ padding: 10, textAlign: "center" }}
              >
                All classes are already rolled over
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <Button
                  onClick={() => navigate("/student/list")}
                  variant="contained"
                  style={{ backgroundColor: "#00868D", marginRight: 20 }}
                >
                  OK
                </Button>
              </div>
            </>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Typography
                  style={{ color: "#00868D" }}
                  mb={2}
                  variant="h6"
                  component="h2"
                >
                  Classes Mapping
                </Typography>
                {!isFieldPresent ? (
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ padding: 10, textAlign: "center" }}
                  >
                    Please click on add for mapping the class and sections
                  </Typography>
                ) : (
                  <></>
                )}
                {fields?.map((field, index) => {
                  return (
                    <StudentRollOverClassesMap
                      key={field.id}
                      index={index}
                      gradesData={gradesData}
                      mappings={mappingData}
                      onMappingChange={handleMappingChange}
                      setValue={setValue}
                      trigger={trigger}
                      errors={errors}
                      setMappingData={setMappingData}
                      remove={remove}
                      studentWiseMapping={studentWiseMapping}
                      setStudentWiseMapping={setStudentWiseMapping}
                      fields={fields}
                    />
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {!studentWiseMapping && (
                    <AddCircleIcon
                      style={{ cursor: "pointer" }}
                      type="button"
                      onClick={() => {
                        append(initial);
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ backgroundColor: "#00868D", marginRight: 20 }}
                      disabled={disableSaveBtn}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => navigate("/student/list")}
                      variant="text"
                      style={{ color: "black" }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </Container>
      <ConfirmationDialogBox
        dialogOpen={showConfirmationBox}
        handleClose={closeConfirmationBox}
        handleConfirm={confirmSubmit}
        dialogeTitle={"Rollover Confirmation"}
        message={
          "Make sure you have entered all the details correctly. Are you sure you want to rollover?"
        }
      />
    </div>
  );
}

export default StudentRollOverMapping;
