import { useContext, useEffect, useRef, useState } from "react";
import { Container, Grid, Stack, styled } from "@mui/material";
import PythonConsole from "../../PythonConsole/PythonConsole";
import loadash from "lodash";
import {
  asyncConsoleRun,
  asyncRun,
  asyncRunTest,
  clearNamespaces,
  interruptCodeExecution,
  interruptCodeExecutionAfterTimeout,
  loadPyodide,
  setInput,
  setPyodideLoaded,
} from "../../ModuleComponents/PyWorker";
import { formatError } from "../../ModuleComponents/Python/PythonFormatError";
import {
  pythonMessages,
  testingCode,
} from "../../../Utils/Constants/PythonConstants";
import GraphicPyodide from "../../ModuleComponents/Python/GraphicsPyodide/graphicPyodide";
import Console from "../../PythonConsole/Console";
import Prism from "prismjs";
import { NotebookContext } from "../../Contexts/NotebookContext";
import { authUserContext } from "../../Contexts/AuthUser";
import PythonInstruction from "../../ModuleComponents/Python/PythonInfo/PythonInstruction";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import PythonGraphicsBox from "../../ModuleComponents/Python/PythonGraphicsBox";
import Tooltip from "../../Tooltips/Tooltip";

const CollapsibleInstructionGrid = styled(Grid)(({ theme, expanded }) => ({
  overflow: "hidden",
  transition: "max-width 0.3s ease, opacity 0.3s ease",
  maxWidth: expanded ? "50%" : "0",
  opacity: expanded ? 1 : 0,
  [theme.breakpoints.down("sm")]: {
    maxWidth: expanded ? "100%" : "0",
  },
}));

function PythonCell(props) {
  const { user } = useContext(authUserContext);
  const {
    notebookData,
    uploadNotebookData,
    isTeachModule,
    currentlyRunningLevel,
    setCurrentlyRunningLevel,
    grade,
    className,
    setShowGoodJob,
    setSuccessMessage,
    handleButtonDisabled,
  } = useContext(NotebookContext);
  const [pythonData, setPythonData] = useState(null);
  const { pythonCellData, notebookType, cellIndex, notebookName } = props;
  const [title, setTitle] = useState("");
  const [preCode, setPreCode] = useState("#Write your code here");
  const [instruction, setInstruction] = useState("Instruction");
  const [conceptDescription, setConceptDescription] = useState(
    "Concept Description"
  );
  const [consoleElement, setConsoleElement] = useState(null);
  const [currentLevelJsonConfig, setCurrentLevelJsonConfig] = useState(null);
  const teacherSeeingStudentWork = props?.teacher && !props?.teacherView;
  const [isPyodideLoaded, setIsPyodideLoaded] = useState(false);
  const pythonGraphicsMode = pythonCellData?.graphics;
  const helpInstructionIds = pythonCellData?.helpInstructionIds;
  const [showTestCase, setShowTestCase] = useState();
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [isGraphicMode, setIsGraphicMode] = useState(false);
  const [graphicPyodide, setGraphicPyodide] = useState(null);
  const [pythonGraphicsSetupCompleted, setPythonGraphicsSetupCompleted] =
    useState(false);
  const [userGraphicCode, setUserGraphicCode] = useState(null);
  const [showSkipLevelButton, setShowSkipLevelButton] = useState(false);
  const [preLoadedImage, setPreLoadedImage] = useState();
  const [isProgramRunning, setIsProgramRunning] = useState(false);
  const [programCurrentlyRunning, setProgramCurrentlyRunning] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const inputBox = useRef(null);
  const sendInputBtn = useRef(null);
  const testingTimeout = useRef(null);
  const pythonContainerRef = useRef(null);

  let editor = document.querySelector(`#${notebookType}-editor-${cellIndex}`);
  let aceEditor = window.ace.edit(editor, {
    theme: "ace/theme/cloud",
    mode: "ace/mode/python",
  });

  aceEditor.setOptions({
    enableBasicAutocompletion: true,
    enableSnippets: true,
    enableLiveAutocompletion: true,
  });

  useEffect(() => {
    if (!pythonGraphicsMode) loadPyodide();
    setIsGraphicMode(pythonCellData?.graphics);
    let consoleObj = new Console(`${notebookType}-console-${cellIndex}`);
    setConsoleElement(consoleObj);
    if (pythonGraphicsMode) {
      setGraphicPyodide(new GraphicPyodide(consoleObj));
    }
  }, []);

  useEffect(() => {
    if (!isPyodideLoaded) {
      let interval;
      interval = setInterval(() => {
        setIsPyodideLoaded(setPyodideLoaded());
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }
    if (isPyodideLoaded) {
      consoleElement.enable();
    }
  }, [isPyodideLoaded]);

  useEffect(() => {
    const runFunctionsAsync = async () => {
      if (graphicPyodide) {
        await graphicPyodide.setup();
        graphicPyodide.setOnErrorCallback(async (error) => {
          console.log(error);
          if (
            error !==
              "Exception: " + pythonMessages.interruptExecutionMessage &&
            !teacherSeeingStudentWork
          ) {
            if (pythonData?.status !== "completed") {
              pythonData.test_cases = pythonMessages.compilationErrorMsg;
              await updatePythonData();
            }
          }
          programCompletedRunning();
        });
        setIsPyodideLoaded(true);
        setPythonGraphicsSetupCompleted(true);
      }
    };
    runFunctionsAsync();
  }, [graphicPyodide]);

  useEffect(() => {
    if (notebookData) {
      const data = notebookData[`${notebookType}_levels`] || {};
      if (data[cellIndex]) {
        const cellData = {
          ...data[cellIndex],
        };
        if (
          notebookType === "assessment" &&
          cellData.status !== "completed" &&
          cellData.attempts >= 10
        ) {
          setShowSkipLevelButton(true);
        }
        setPythonData(cellData);
      } else {
        setPythonData({
          type: "Python",
          status: "incomplete",
          attempts: 0,
        });
      }
    }
  }, [notebookData]);

  useEffect(() => {
    if (consoleElement) loadLevel();
  }, [pythonGraphicsSetupCompleted, consoleElement]);

  useEffect(() => {
    if (scrollToBottom && pythonContainerRef.current && showTestCase?.length) {
      pythonContainerRef.current.scrollTop =
        pythonContainerRef.current.scrollHeight;
      setScrollToBottom(false);
    }
  }, [scrollToBottom, showTestCase]);

  useEffect(() => {
    if (currentlyRunningLevel !== cellIndex) {
      if (isProgramRunning) interruptCodeExecution();
      if (programCurrentlyRunning) stopPythonGraphicsCode();
    }

    return () => {
      if (isProgramRunning) interruptCodeExecution();
      if (programCurrentlyRunning) stopPythonGraphicsCode();
    };
  }, [currentlyRunningLevel]);

  useEffect(() => {
    if (pythonGraphicsMode) {
      if (!programCurrentlyRunning) {
        aceEditor?.focus();
      }
    } else {
      if (!isProgramRunning) {
        aceEditor?.focus();
      }
    }
  }, [isProgramRunning, programCurrentlyRunning]);

  async function loadLevel() {
    if (isProgramRunning) interruptCodeExecution();
    if (programCurrentlyRunning) stopPythonGraphicsCode();
    setConceptDescription();
    setInstruction();
    setShowTestCase(undefined);
    let graphicType;
    await fetch(pythonCellData?.level?.configFile)
      .then((res) => res.json())
      .then((result) => {
        graphicType = result.graphicType;
        setCurrentLevelJsonConfig(result);
      });
    setTitle(pythonCellData?.level?.title);
    const exerciseNotebook = notebookType === "exercise";
    if (exerciseNotebook && pythonCellData?.level?.demoLevel) {
      setPreCode(pythonCellData?.level?.solution);
    } else {
      setPreCode(pythonCellData?.level?.preLoadedCode);
    }
    setInstruction(pythonCellData?.level?.hint);
    setConceptDescription(pythonCellData?.level?.problemDescription);
    if (exerciseNotebook && pythonCellData?.level?.demoLevel) {
      aceEditor.setValue(pythonCellData?.level?.solution, -1);
    } else if (pythonData?.giveup) {
      loadSolution();
    } else if (pythonData?.code) {
      aceEditor.setValue(pythonData.code, -1);
    } else {
      aceEditor.setValue(pythonCellData?.level?.preLoadedCode, -1);
    }
    aceEditor.focus();
    if (pythonGraphicsMode) {
      setPreLoadedImage(pythonCellData?.level?.preLoadedImage);
    }
    if (pythonGraphicsMode) {
      loadGraphicType(graphicType);
    } else {
      clearNamespaces();
    }
    clearConsoleHandler();
    const codeElements = pythonContainerRef?.current?.querySelectorAll("code");
    codeElements?.forEach((codeElement) => {
      codeElement.classList.add(`language-python`);
    });
    Prism.highlightAll();
  }

  function setOutputToConsole(output, errorOccurred = false) {
    consoleElement.addMessage(output + "\n", errorOccurred);
  }

  function resetCode() {
    aceEditor.setValue(preCode, -1);
  }

  const updatePythonData = async (data = pythonData) => {
    if (
      notebookType === "assessment" &&
      data.status !== "completed" &&
      data.attempts >= 10
    ) {
      setShowSkipLevelButton(true);
    }
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: data,
      },
    };
    if (data.status === "completed" && isTeachModule) {
      const pythonLevelsCompletedKey = `${notebookType}_python_levels_completed`;
      uploadData[pythonLevelsCompletedKey] = notebookData[
        pythonLevelsCompletedKey
      ]
        ? notebookData[pythonLevelsCompletedKey] + 1
        : 1;
      const levelsCompletedKey = `${notebookType}_levels_completed`;
      uploadData[levelsCompletedKey] = notebookData[levelsCompletedKey]
        ? notebookData[levelsCompletedKey] + 1
        : 1;
    }
    if ("giveup" in data && data["giveup"]) {
      uploadData["is_python_level_giveup"] = 1;
    }
    return await uploadNotebookData(uploadData);
  };

  const runCode = async () => {
    let pythonDataCopy = loadash.cloneDeep(pythonData);
    setCurrentlyRunningLevel(cellIndex);
    clearConsoleHandler();
    setShowTestCase(undefined);
    setIsProgramRunning(true);
    pythonDataCopy.code = aceEditor.getValue();
    if (pythonDataCopy.status !== "completed") {
      pythonDataCopy.attempts++;
      await updatePythonData(pythonDataCopy);
    }
    try {
      inputBox.current.value = "";
      const { results, error, interrupted } = await asyncRun(
        pythonDataCopy.code,
        `${notebookType}-console-${cellIndex}`
      );
      console.log(
        `results: ${results}, error: ${error}, interrupted: ${interrupted}`
      );
      if (results) {
        setOutputToConsole(results);
      } else if (error) {
        setShowTestCase(undefined);
        setOutputToConsole(
          formatError(
            error,
            false,
            pythonDataCopy.code,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage
          ),
          true
        );
      }
      let testingResults;
      let testCases = [];
      if (results !== undefined) {
        setScrollToBottom(true);
        ({ testingResults } = await asyncRunTest(
          pythonDataCopy.code,
          results,
          currentLevelJsonConfig,
          testingCode
        ));
      }
      // Checking if the level is success or failure
      let success = true;
      if (testingResults === undefined) {
        success = false;
      } else {
        for (let item of testingResults) {
          let testCase = Object.fromEntries([...item]);
          testCases.push(testCase);
          if (!("result" in testCase) || testCase.result === "fail")
            success = false;
        }
      }
      if (!teacherSeeingStudentWork) {
        if (pythonDataCopy?.status !== "completed") {
          if (testingResults) {
            pythonDataCopy.test_cases = testCases;
          } else if (interrupted === false) {
            pythonDataCopy.test_cases = pythonMessages.compilationErrorMsg;
          }
          if (success) {
            pythonDataCopy.status = "completed";
          } else {
            console.log(testingResults, "testingResults");
          }
          let uploadStatus = await updatePythonData(pythonDataCopy);
          if (!uploadStatus) {
            setIsProgramRunning(false);
            return;
          }
        }
      }
      if (testCases.length) setExpanded(true);
      setShowTestCase(testCases);
      if (success) {
        setShowGoodJob(true);
        setSuccessMessage("You completed the level!");
      }
    } catch (e) {
      setOutputToConsole(
        formatError(
          e,
          false,
          pythonDataCopy.code,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage
        ),
        true
      );
    }
    setIsProgramRunning(false);
  };

  function programCompletedRunning() {
    clearTimeout(testingTimeout.current);
    testingTimeout.current = null;
    if (inputBox.current.value.trim() === "")
      sendInputBtn.current.disabled = true;
    else sendInputBtn.current.disabled = false;
    inputBox.current.disabled = false;
    setProgramCurrentlyRunning(false);
  }

  function loadGraphicType(graphicType) {
    graphicPyodide.setGraphicType(graphicType);
  }

  async function runPythonGraphicCode() {
    let pythonDataCopy = loadash.cloneDeep(pythonData);
    setCurrentlyRunningLevel(cellIndex);
    setShowTestCase(undefined);
    if (programCurrentlyRunning) return;
    let userCode = aceEditor.getValue();
    pythonDataCopy.code = userCode;
    pythonDataCopy.attempts++;
    programRunning(userCode, pythonDataCopy);
    consoleElement.clear();
    setUserGraphicCode(userCode);
    graphicPyodide.runCode(userCode, notebookType, cellIndex + 1);
  }

  function stopPythonGraphicsCode() {
    if (!programCurrentlyRunning) return;
    graphicPyodide?.stopExecution();
  }

  async function programRunning(userCode = userGraphicCode, data = pythonData) {
    if (!teacherSeeingStudentWork) {
      if (data.status !== "completed") {
        await updatePythonData(data);
      }
    }
    testingTimeout.current = setTimeout(async () => {
      await runTests(userCode, data);
    }, 3000);
    sendInputBtn.current.disabled = true;
    inputBox.current.disabled = true;
    setProgramCurrentlyRunning(true);
  }

  async function runTests(codeToCheck, data = pythonData) {
    let testResults = graphicPyodide.runTests(
      codeToCheck,
      currentLevelJsonConfig
    );
    let testCases = [];
    if (testResults !== undefined) {
      let success = true;
      if (testResults === undefined) {
        success = false;
      } else {
        for (let item of testResults) {
          let testCase = Object.fromEntries([...item]);
          testCases.push(testCase);
          if (!("result" in testCase) || testCase.result === "fail")
            success = false;
        }
      }
      if (!teacherSeeingStudentWork) {
        if (data.status !== "completed") {
          data.test_cases = testCases;
          if (success) {
            data.status = "completed";
          }
          let uploadStatus = await updatePythonData(data);
          if (!uploadStatus) {
            return;
          }
        }
      }
      if (testCases.length) setExpanded(true);
      setShowTestCase(testCases);
      setScrollToBottom(true);
      if (success) {
        setSuccessMessage("You completed the level!");
        setShowGoodJob(true);
      }
    }
  }

  function handleInput(inputElement) {
    if (programCurrentlyRunning) return;
    let command = inputElement.value;
    if (pythonGraphicsMode) {
      if (command.trim() === "") return;
      inputElement.value = "";
      if (command == "") return;
      consoleElement.addCommand(command);
      graphicPyodide.evaluateConsoleCode(command);
    } else {
      if (isProgramRunning) {
        inputElement.value = "";
        setInput(command);
      } else {
        if (command.trim() === "") return;
        inputElement.value = "";
        if (command === "") return;
        consoleElement.addCommand(command);
        evaluateTextConsoleCode(command);
      }
    }
  }

  async function evaluateTextConsoleCode(command) {
    try {
      if (command.trim() === "") return;
      if (command === "") return;
      setTimeout(interruptCodeExecutionAfterTimeout, 3000);
      const { output, error } = await asyncConsoleRun(command);
      console.log(output, error);
      if (output) {
        setOutputToConsole(output);
      } else if (error) {
        setOutputToConsole(
          formatError(
            error,
            true,
            command,
            pythonMessages.interruptExecutionMessage,
            pythonMessages.inputDisabledInConsoleMessage,
            pythonMessages.timeoutMessage
          ),
          true
        );
      }
    } catch (error) {
      setOutputToConsole(
        formatError(
          error,
          true,
          command,
          pythonMessages.interruptExecutionMessage,
          pythonMessages.inputDisabledInConsoleMessage,
          pythonMessages.timeoutMessage
        ),
        true
      );
    }
  }

  const consoleInputKeyDownHandler = (event) => {
    if (event.key === "Enter") {
      sendInputBtn.current.disabled = true;
      handleInput(inputBox.current);
    }
  };

  const consoleInputChangeHandler = () => {
    if (inputBox.current.value.trim() === "") {
      sendInputBtn.current.disabled = true;
    } else {
      sendInputBtn.current.disabled = false;
    }
  };

  const consoleSendBtnClickHandler = () => {
    sendInputBtn.current.disabled = true;
    handleInput(inputBox.current);
  };

  const clearConsoleHandler = () => {
    consoleElement?.clear();
  };

  const loadSolution = () => {
    aceEditor.setValue(pythonCellData?.level?.solution, -1);
    aceEditor.focus();
    aceEditor.gotoLine(1);
    aceEditor.scrollToLine(0, true, true);
  };

  const giveupLevel = async () => {
    let pythonDataCopy = loadash.cloneDeep(pythonData);
    if (notebookType !== "assessment") return;
    pythonDataCopy.status = "completed";
    pythonDataCopy.giveup = 1;
    await updatePythonData(pythonDataCopy);
    setShowSkipLevelButton(false);
    loadSolution();
  };

  return (
    <Container
      maxWidth={false}
      id="python-view"
      sx={{ display: "flex !important" }}
      className="content-tab"
    >
      <div className="python-drawer-box notebook-drawer-box">
        <Tooltip
          placement="bottom"
          title={expanded ? "Hide" : "Show"}
          sx={{ zIndex: "10" }}
        >
          <div onClick={() => setExpanded((pre) => !pre)}>
            {expanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </div>
        </Tooltip>
      </div>
      <Grid
        container
        height="100%"
        className={isGraphicMode ? "graphic-mode-python-container" : ""}
      >
        <CollapsibleInstructionGrid
          item
          expanded={expanded}
          className="python-instruction-container"
          sx={{ height: "100%", flex: 1 }}
        >
          <PythonInstruction
            pythonContainerRef={pythonContainerRef}
            showTestCase={showTestCase}
            title={title}
            conceptDescription={conceptDescription}
            instruction={instruction}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </CollapsibleInstructionGrid>
        <Grid
          item
          className="python-code-editor-container"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <div id="container" style={{ margin: "5px" }}>
            <Stack direction="row" spacing={2}>
              <button
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "#aecb2a" }}
                onClick={() => {
                  if (isGraphicMode) {
                    runPythonGraphicCode();
                  } else {
                    runCode();
                  }
                }}
                disabled={
                  !isPyodideLoaded ||
                  isProgramRunning ||
                  programCurrentlyRunning ||
                  handleButtonDisabled()
                }
              >
                Run
              </button>
              <button
                disabled={
                  (isGraphicMode
                    ? !programCurrentlyRunning
                    : !isProgramRunning) || handleButtonDisabled()
                }
                className="reset-code"
                style={{ lineHeight: "2.2vw", backgroundColor: "red" }}
                onClick={() => {
                  if (isGraphicMode) {
                    stopPythonGraphicsCode();
                  } else {
                    interruptCodeExecution();
                  }
                }}
              >
                Stop
              </button>
              <button
                className="reset-code"
                style={{ lineHeight: "2.2vw" }}
                onClick={() => resetCode()}
                disabled={handleButtonDisabled()}
              >
                Reset
              </button>
            </Stack>
            <Stack direction="row" spacing={2}>
              {isTeachModule && (
                <button
                  className="reset-code"
                  id="python-solution-button"
                  style={{ lineHeight: "2.2vw", backgroundColor: "#aecb2a" }}
                  onClick={() => loadSolution()}
                  disabled={handleButtonDisabled()}
                >
                  Solution
                </button>
              )}
              {user?.role?.includes("student") && showSkipLevelButton && (
                <button
                  className="giveup-button ml-auto"
                  onClick={giveupLevel}
                  disabled={handleButtonDisabled()}
                >
                  Skip Level
                </button>
              )}
            </Stack>
          </div>
          <div className="content-tab">
            <div
              className="editor-container"
              style={isGraphicMode ? { height: "calc(100% - 1px)" } : {}}
            >
              <div
                id={`${notebookType}-editor-${cellIndex}`}
                className="editor"
              ></div>
            </div>
            {!isGraphicMode && (
              <div className="console-container">
                <PythonConsole
                  inputBoxRef={inputBox}
                  sendButtonRef={sendInputBtn}
                  onClearConsole={clearConsoleHandler}
                  inputBoxOnKeyDown={consoleInputKeyDownHandler}
                  inputBoxOnChange={consoleInputChangeHandler}
                  onSendButtonClick={consoleSendBtnClickHandler}
                  consoleId={`${notebookType}-console-${cellIndex}`}
                />
              </div>
            )}
          </div>
        </Grid>
        {isGraphicMode && (
          <Grid
            item
            className="graphic-window-panel"
            style={{ height: "100%" }}
          >
            <PythonGraphicsBox
              sketchHolderId={
                props?.cellIndex >= 0 && props?.notebookType
                  ? `${props?.notebookType}-sketch-holder-${
                      props?.cellIndex + 1
                    }`
                  : "console"
              }
              preLoadedImage={preLoadedImage}
              programCurrentlyRunning={programCurrentlyRunning}
              helpInstructionIds={helpInstructionIds}
              moduleName={notebookName}
              grade={grade}
              className={className}
            />
            <div className="graphic-console">
              <PythonConsole
                inputBoxRef={inputBox}
                sendButtonRef={sendInputBtn}
                onClearConsole={clearConsoleHandler}
                inputBoxOnKeyDown={consoleInputKeyDownHandler}
                inputBoxOnChange={consoleInputChangeHandler}
                onSendButtonClick={consoleSendBtnClickHandler}
                consoleId={`${notebookType}-console-${cellIndex}`}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default PythonCell;
