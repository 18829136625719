import React from "react";
import PopupBox from "../Dialogs/PopupBox";
import DialogBoxTitle from "../Dialogs/DialogBoxTitle";

const dialogBoxStyle = {
  "& .MuiPaper-root": {
    fontFamily: "rajdhani-medium",
    border: "3px solid #25A596",
    borderRadius: "4vw",
    maxWidth: "60vw",
    width: "60vw",
    height: "70vh",
    position: "absolute",
    top: 0,
    backgroundColor: "rgba(255,255,255,0.85)",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  "& 	.MuiIconButton-root": {
    padding: 0,
  },
  "& 	.MuiSvgIcon-root": {
    height: "1.5vw",
    width: "1.5vw",
  },
};

const customTitleStyle = { m: 0, p: 0 };

const customCloseIconStyle = {
  height: "0.5vw",
  poadding: 0,
  position: "absolute",
  right: "1vw",
  top: "1.5vw",
  color: "#343a40",
};

function PlayVideo(props) {
  const { videoUrl, setHelpVideoPopup, helpVideoPopup } = props;

  function handleHelpPopupClose() {
    setHelpVideoPopup(false);
  }
  const handleVideoLoaded = () => {
    const video = document.getElementById("game-help-video");
    video.play();
  };

  return (
    <PopupBox
      onClose={handleHelpPopupClose}
      aria-labelledby="customized-dialog-title"
      open={helpVideoPopup}
      customDialogStyle={dialogBoxStyle}
    >
      <DialogBoxTitle
        id="customized-dialog-title"
        onClose={handleHelpPopupClose}
        customTitleStyle={customTitleStyle}
        customCloseIconStyle={customCloseIconStyle}
      />
      <div className="play-video-help">
        <video
          id="game-help-video"
          width="100%"
          controls
          onLoadedMetadata={handleVideoLoaded}
          style={{ borderRadius: "1vw" }}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
    </PopupBox>
  );
}

export default PlayVideo;
