import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssessmentSnapshot from "../../Assets/snapshots/AssessmentSnapshotHome.jpg";
import CompletionSnapshot from "../../Assets/snapshots/CompletionSnapshotHome.jpg";
import GameSnapshot from "../../Assets/snapshots/GameSnapshotHome.jpg";
import ProjectSnapshot from "../../Assets/snapshots/ProjectSnapshot.jpg";
import PuzzleSnapshot from "../../Assets/snapshots/PuzzleSnapshotHome.jpg";
import StorySnapshot from "../../Assets/snapshots/StorySnapshotHome.jpg";
import AssesmentButton from "../ModuleComponentsButtons/AssesmentButton";
import GameButton from "../ModuleComponentsButtons/GameButton";
import ProjectButton from "../ModuleComponentsButtons/ProjectButton";
import PuzzleButton from "../ModuleComponentsButtons/PuzzleButton";
import StoryButton from "../ModuleComponentsButtons/StoryButton";
import PythonButton from "../ModuleComponentsButtons/PythonButton";
import ModuleCalender from "./Dashboard/ModuleCalender";
import TeacherFeedback from "./Dashboard/TeacherFeedback";

export default function LetsWork(props) {
  document.title = "CSSoch | Home";
  const [isLoading, setIsLoading] = useState(true);
  const [moduleData, setModuleData] = useState();
  const [isModuleLocked, setIsModuleLocked] = useState(false);
  const [snapshotSrc, setSnapshotSrc] = useState();
  const [currentModuleID, setCurrentModuleID] = useState();
  const [feedback, setFeedback] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    if (props.moduleData) {
      const data = props.moduleData;
      setModuleData(props.moduleData);
      setCurrentModuleID(data.id);
      setFeedback(data.feedback)
      initializeHomeScreen(data);
    }
  }, [props.moduleData]);
  function initializeHomeScreen(data) {
    if (data.status === 'locked') {
      setIsModuleLocked(true);
      setIsLoading(false);
      return;
    }
    setIsModuleLocked(false);

    const { structure } = data;
    let firstFoundIncomplete = false;
    let snapShotFound = false;
    for (let i = 0; i < structure.length; i++) {
      if (data.statuses[structure[i]] === "ongoing") {
        firstFoundIncomplete = true;
        break;
      }
    }
    for (let i = 0; i < structure.length; i++) {
      if (
        !firstFoundIncomplete &&
        data.statuses[structure[i]] === "incomplete"
      ) {
        firstFoundIncomplete = true;
        data.statuses[structure[i]] = "ongoing";
      }
      if (data.statuses[structure[i]] === 'ongoing') {
        if (structure[i] === 'story' || structure[i] === 'exercise') setSnapshotSrc(StorySnapshot);
        if (structure[i] === 'game') setSnapshotSrc(GameSnapshot);
        if (structure[i] === 'puzzle') setSnapshotSrc(PuzzleSnapshot);
        // TODO: Replace the below snapshot with the python snapshot
        if (structure[i] === 'python') setSnapshotSrc(GameSnapshot);
        if (structure[i] === 'assesment' ||structure[i] === 'assessment' ) setSnapshotSrc(AssessmentSnapshot);
        if (structure[i] === 'project') setSnapshotSrc(ProjectSnapshot);
        snapShotFound = true;
      }
    }
    if (!snapShotFound) setSnapshotSrc(CompletionSnapshot);
    setIsLoading(false);
  }
  function onSnapshotClick() {
    props.callClickSound();
    if(props.currentModuleType ==='module'){
      navigate(`/modules/${currentModuleID}`);
    }
    else {
      const initial = moduleData.statuses?.exercise === 'ongoing' || moduleData.statuses?.exercise === 'incomplete'|| moduleData.statuses?.assessment !== 'ongoing' ? 'Exercise' : 'Assessment';
      navigate(`/notebook`,{state:{"notebookId": currentModuleID, "initial": initial}});
    }
  }
  return (
    <div className='letswork-content'>
      {!isLoading ? (
        !isModuleLocked ? (
          <>
            <p className='dashboard-module-header'>
              Module
              {` ${props.currentModuleNum}`}
              :
              {` ${moduleData.name}`}
            </p>
            <div className='module-details'>
              <div className='module-buttons-container'>
                <div className='row module-buttons'>
                  <div className='module-container col-4 p-0'>
                    {moduleData.structure.includes("story") && (
                      <StoryButton
                        status={moduleData.statuses.story}
                        onButtonClicked={props.callClickSound}
                        link={`/modules/${currentModuleID}?initial=story`}
                      />
                    )}
                    {moduleData.structure.includes("game") && (
                      <GameButton
                        isStoryPresent={moduleData.structure.includes('story') ? true : false}
                        status={moduleData.statuses.game}
                        onButtonClicked={props.callClickSound}
                        link={`/modules/${currentModuleID}?initial=game`}
                      />
                    )}
                    {moduleData.structure.includes("python") && (
                      <PythonButton
                        isStoryPresent={moduleData.structure.includes('story') ? true : false}
                        isGamePresent={moduleData.structure.includes('game') ? true : false}
                        status={moduleData.statuses.python}
                        onButtonClicked={props.callClickSound}
                        link={`/modules/${currentModuleID}?initial=python`}
                      />
                    )}
                    {moduleData.structure.includes("puzzle") && (
                      <PuzzleButton
                        isStoryPresent={moduleData.structure.includes('story') ? true : false}
                        isGamePresent={moduleData.structure.includes('game') ? true : false}
                        isPythonPresent={moduleData.structure.includes('python') ? true : false}
                        status={moduleData.statuses.puzzle}
                        onButtonClicked={props.callClickSound}
                        link={`/modules/${currentModuleID}?initial=puzzle`}
                      />
                    )}
                    {moduleData.structure.includes('assessment')  && props.currentModuleType === 'module' && (
                      <AssesmentButton
                        isStoryPresent={moduleData.structure.includes('story') ? true : false}
                        isGamePresent={moduleData.structure.includes('game') ? true : false}
                        isPythonPresent={moduleData.structure.includes('python') ? true : false}
                        isPuzzlePresent={moduleData.structure.includes('puzzle') ? true : false}
                        assesmentDetails={{
                          status : moduleData?.statuses?.assessment,
                          isgamelevelgiveup: moduleData?.is_game_level_giveup,
                          ispuzzlelevelgiveup: moduleData?.is_puzzle_level_giveup,
                          ispythonlevelgiveup: moduleData?.is_python_level_giveup,
                        }}
                        onButtonClicked={null}
                        link={`/modules/${currentModuleID}?initial=assessment`}
                      />
                    )}
                    {moduleData.structure.includes("project") && (
                      <ProjectButton
                        status={moduleData.statuses.project}
                        onButtonClicked={props.callClickSound}
                        link={`/modules/${currentModuleID}?initial=project`}
                      />
                    )}
                    {moduleData.structure.includes('exercise') && (
                      <StoryButton
                        status={moduleData.statuses.exercise}
                        onButtonClicked={props.callClickSound}
                        link={'/notebook'}
                        linkState={{"notebookId": currentModuleID, "initial": "Exercise"}}
                      />
                    )}
                    {moduleData.structure.includes('assessment') && props.currentModuleType === 'notebook' && (
                      <AssesmentButton
                        isExercisePresent={moduleData.structure.includes('exercise') ? true : false}
                        exerciseStatus={moduleData.statuses.exercise}
                        assesmentDetails={{
                          status : moduleData?.statuses?.assessment,
                          isgamelevelgiveup: moduleData?.is_game_level_giveup,
                          ispuzzlelevelgiveup: moduleData?.is_puzzle_level_giveup,
                          ispythonlevelgiveup: moduleData?.is_python_level_giveup,
                        }}
                        onButtonClicked={props.callClickSound}
                        link={'/notebook'}
                        isNotNotebookExercise = {!moduleData.structure.includes('exercise') && moduleData?.module_type==='notebook' ? true : false}
                        linkState={{"notebookId": currentModuleID, "initial": "Assessment"}}
                      />
                    )}
                  </div>
                  <div className='module-container-labels col-8 p-0'>
                    {moduleData.structure.includes("story") && <div className={`${moduleData?.statuses?.story ==='ongoing' ? 'ongoing-pointer': ''} `}> <p>story</p></div>}
                    {moduleData.structure.includes("game") && <div className={`${moduleData?.statuses?.game ==='ongoing' ? 'ongoing-pointer': ''} `}><p>games</p></div>}
                    {moduleData.structure.includes("python") && <div className={`${moduleData?.statuses?.python ==='ongoing' ? 'ongoing-pointer': ''} `}><p>python</p></div>}
                    {moduleData.structure.includes("puzzle") && <div className={`${moduleData?.statuses?.puzzle ==='ongoing' ? 'ongoing-pointer': ''} `}><p>puzzles</p></div>}
                    {moduleData.structure.includes("assessment") && props.currentModuleType === 'module' && (
                     <div className={`${moduleData?.statuses?.assessment ==='ongoing' ? 'ongoing-pointer': ''} `}> <p>assessment</p></div>
                    )}
                    {moduleData.structure.includes('project') && <div className={`${moduleData?.statuses?.project ==='ongoing' ? 'ongoing-pointer': ''} `}><p>project</p></div>}
                    {moduleData.structure.includes('exercise') && <div className={`${moduleData?.statuses?.exercise ==='ongoing' ? 'ongoing-pointer': ''} `}><p>exercise</p></div>}
                    {moduleData.structure.includes('assessment') && props.currentModuleType === 'notebook' && <div className={`${moduleData?.statuses?.assessment ==='ongoing' ? 'ongoing-pointer': ''} `}><p>assessment</p></div>}
                  </div>
                </div>
              </div>
              <div className='module-image-container'>
                <div className='snapshot-holder'>
                  <img
                    src={snapshotSrc}
                    className='snapshot-img'
                    width='100%'
                    alt=''
                    onClick={onSnapshotClick}
                  />
                </div>
              </div>
              <div className='module-calender-container'>
                <div className='calender-view'>
                  <ModuleCalender assignment={props.currentModuleAssignment} />
                </div>
                <div className='student-dashboard-feedback'>
                  <TeacherFeedback
                    feedback={feedback}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className='locked-box'>
            <p>
              There is no work to do yet. Wait for your teacher to assign work.
            </p>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
