import {
  Container,
  Typography,
  Grid,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
  Table,
  TableCell,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";

function ClassTeachersDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [classes, setClasses] = useState({});
  const [classTeachers, setClassTeachers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClassTeachersDetails = async () => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/get-class-teachers`)
        .then((response) => response.json())
        .then((data) => {
          setClasses(data.classes);
          setClassTeachers(data.classTeachers);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchClassTeachersDetails();
  }, []);

  const navigateToEditClassTeachers = () => {
    navigate("/class-teachers/edit", {
      state: { classes, classTeachers },
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ backgroundColor: "#DDE9F5" }}>
      <Container>
        <Typography variant="h6" component="h2" style={{ padding: 10 }}>
          Class Teacher Assignment
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            marginTop: 5,
            padding: 20,
            borderRadius: 6,
          }}
        >
          {Object.keys(classes).map((grade) => (
            <Grid
              container
              spacing={2}
              key={grade}
              sx={{ margin: 2, width: "auto" }}
            >
              <Typography variant="h6" component="h2">
                Grade {grade}
              </Typography>
              <Grid container sx={{ margin: 1 }}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Sections</TableCell>
                        <TableCell align="left">Class Teachers</TableCell>
                      </TableRow>
                    </TableHead>
                    {classes[grade].map((section) => (
                      <TableBody>
                        <TableRow
                          key={classTeachers?.[grade]?.[section] || section}
                        >
                          <TableCell variant="head" component="th" scope="row">
                            {section}
                          </TableCell>
                          <TableCell align="left">
                            {
                              classTeachers?.[grade]?.[section]
                                ?.assigned_teacher
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: "#00868D" }}
              onClick={navigateToEditClassTeachers}
            >
              Edit
            </Button>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default ClassTeachersDetails;
