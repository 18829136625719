import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import React from "react";
import { Link } from "react-router-dom";
import Bar from "../../Assets/Images/Bar.svg";

const colourMappings = {
  completed: "#25A596",
  incomplete: "#FE7D00",
  ongoing: "#AECB2A",
  locked: "#8B8B8B",
  gaveup: "#B30A74",
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontFamily: "rajdhani-medium",
  },
}));
export default function AssesmentButton(props) {
  const currentAssesmentStatus =
    props.assesmentDetails?.status === "completed" &&
    (props.assesmentDetails?.isgamelevelgiveup ||
      props.assesmentDetails?.ispuzzlelevelgiveup ||
      props.assesmentDetails?.ispythonlevelgiveup)
      ? "gaveup"
      : "";
  const svgIconElem = (
    <g transform="translate(-14111 9048)">
      <circle
        style={{
          fill: `${
            colourMappings[
              currentAssesmentStatus
                ? currentAssesmentStatus
                : props.assesmentDetails?.status
                ? props.assesmentDetails?.status
                : "incomplete"
            ]
          }`,
        }}
        id="assesment-icon-coloring"
        cx="60.5"
        cy="60.5"
        r="60.5"
        transform="translate(14111 -9048)"
        fill="#25a596"
      />
      <g transform="translate(14143.807 -9022.872)">
        <g transform="translate(0 0)">
          <path
            d="M64.929,13.032H59.9A12.131,12.131,0,0,0,47.934,2.5,12.273,12.273,0,0,0,35.887,13.032H30.94A5.313,5.313,0,0,0,25.674,18.3v4.308H21.366A5.261,5.261,0,0,0,16.1,27.872V73.11a5.313,5.313,0,0,0,5.266,5.266H55.275A5.313,5.313,0,0,0,60.54,73.11V68.8h4.308a5.313,5.313,0,0,0,5.266-5.266V18.3A5.194,5.194,0,0,0,64.929,13.032ZM47.934,5.851a8.856,8.856,0,1,1-8.856,8.856A8.887,8.887,0,0,1,47.934,5.851ZM57.269,73.03a1.941,1.941,0,0,1-1.915,1.915H21.446a1.959,1.959,0,0,1-1.995-1.915V27.792a1.959,1.959,0,0,1,1.995-1.915h4.308V63.536A5.313,5.313,0,0,0,31.02,68.8H57.349V73.03Zm9.574-9.494a1.941,1.941,0,0,1-1.915,1.915H30.94a1.942,1.942,0,0,1-1.915-1.915V18.3A2.013,2.013,0,0,1,30.94,16.3h4.947a12.157,12.157,0,0,0,24.1,0h4.947A1.959,1.959,0,0,1,66.844,18.3Z"
            transform="translate(-16.1 -2.5)"
            stroke="#000"
            strokeWidth="1"
          />
          <path
            d="M63.716,58.8H41.776a1.675,1.675,0,1,0,0,3.351H63.716a1.685,1.685,0,0,0,1.675-1.675A1.636,1.636,0,0,0,63.716,58.8Z"
            transform="translate(-20.951 -13.881)"
            stroke="#000"
            strokeWidth="1"
          />
          <path
            d="M63.716,69H41.776a1.676,1.676,0,1,0,0,3.351H63.716a1.685,1.685,0,0,0,1.675-1.676A1.636,1.636,0,0,0,63.716,69Z"
            transform="translate(-20.951 -15.943)"
            stroke="#000"
            strokeWidth="1"
          />
          <path
            d="M63.716,48.5H41.776a1.675,1.675,0,1,0,0,3.351H63.716a1.675,1.675,0,0,0,0-3.351Z"
            transform="translate(-20.951 -11.799)"
            stroke="#000"
            strokeWidth="1"
          />
          <path
            d="M63.716,38.3H41.776a1.675,1.675,0,1,0,0,3.351H63.716a1.685,1.685,0,0,0,1.675-1.675A1.636,1.636,0,0,0,63.716,38.3Z"
            transform="translate(-20.951 -9.737)"
            stroke="#000"
            strokeWidth="1"
          />
          <path
            d="M52.182,20.869a1.7,1.7,0,0,0,1.2.559,1.436,1.436,0,0,0,1.2-.559L59.123,16a1.637,1.637,0,0,0-2.394-2.234l-3.351,3.59-1.2-1.277a1.637,1.637,0,1,0-2.394,2.234Z"
            transform="translate(-22.821 -4.673)"
            stroke="#000"
            strokeWidth="1"
          />
        </g>
      </g>
    </g>
  );
  if (props.icon) {
    return (
      <svg
        id="assesment-icon"
        className="nav-button"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 121 121"
      >
        {svgIconElem}
      </svg>
    );
  }
  return (
    <>
      {props.isStoryPresent ||
      props.isGamePresent ||
      props.isPuzzlePresent ||
      props.isPythonPresent ||
      props.isExercisePresent ? (
        <img src={Bar} className="bar" />
      ) : (
        <></>
      )}
      <BootstrapTooltip placement="left" title={props.assesmentDetails?.status}>
        <Link
          className={
            props?.exerciseStatus === "incomplete" ||
            props?.exerciseStatus === "ongoing"
              ? "disable-exercise-notebook"
              : ""
          }
          to={props.link ? props.link : ""}
          state={props?.linkState ? props?.linkState : null}
        >
          <svg
            onClick={
              props.onButtonClicked !== null
                ? () => props.onButtonClicked("assessment", true)
                : () => {}
            }
            id="assesment-icon"
            className={`nav-button ${props.active ? "active" : ""}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 121 121"
          >
            {svgIconElem}
          </svg>
        </Link>
      </BootstrapTooltip>
    </>
  );
}
