import React from "react";
import CloseCross from "../../../Assets/Images/CloseCross.svg";
import LevelFailSadEmoji from "../../../Assets/Images/LevelFailSadEmoji.svg";
import HappyEmoji from "../../../Assets/Images/HappyEmoji.svg";

export default function BadJobFeedbackBox(props) {
  const visibility = props.visibility;
  const showFeedback = props.showFeedback;
  const sucessMsg = props.sucessMsg;
  const goToNext = props.goToNext;
  const currentItem = props.currentItem;

  const displayConfirmationButton = () => {
    if (currentItem === "notebook") {
      return (
        <button
          className="feedback-next-level"
          onClick={props.nextBtnOnClickhandler}
        >
          OK
        </button>
      );
    }

    if (goToNext && props.Finish) {
      return (
        <button
          className="feedback-next-level"
          onClick={() => {
            props.handleCurrentSectionChange(
              props.getNext(currentItem).charAt(0).toLowerCase() +
                props.getNext(currentItem).slice(1)
            );
          }}
        >
          {`Go To ${props.getNext(currentItem)}`}
        </button>
      );
    }

    return (
      <button
        className="feedback-next-level"
        onClick={props.nextBtnOnClickhandler}
      >
        {props.Finish ? "Finish" : "Next"}
      </button>
    );
  };

  return (
    <div
      className={`feedback-main-box bad-job-feedback d-flex flex-column align-items-center ${
        visibility ? "active" : ""
      }`}
    >
      <img
        className="close-cross"
        src={CloseCross}
        alt=""
        onClick={() => props.setVisibility(false)}
      />
      <div
        className="heading d-flex justify-content-center align-items-center"
        style={
          showFeedback
            ? { height: "2vw", marginBottom: "0.2vw" }
            : { height: "2vw", marginBottom: "1vw" }
        }
      >
        <h5>Oh No</h5>
        <img src={LevelFailSadEmoji} alt="" />
      </div>
      <div
        className="feedback-collector"
        style={!showFeedback ? { display: "none" } : {}}
      >
        <hr />
        <p>Did you like this problem?</p>
        <div className="row" style={{ justifyContent: "space-around" }}>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            <img
              className="item-liked"
              src={HappyEmoji}
              alt=""
              onClick={props.handleItemLikedFeedback}
            />
            <p>Yes</p>
          </div>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            <img
              className="item-disliked"
              src={LevelFailSadEmoji}
              alt=""
              onClick={props.handleItemDislikedFeedback}
            />
            <p>No</p>
          </div>
        </div>
      </div>
      {displayConfirmationButton()}
    </div>
  );
}
