import {
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";

function EditClassTeachers() {
  const [isLoading, setIsLoading] = useState(true);
  const { classes, classTeachers } = useLocation().state;
  const [classTeachersList, setClassTeachersList] = useState(classTeachers);
  const [updatedClassTeachers, setUpdatedClassTeachers] = useState({});
  const [teachers, setTeachers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllTeachers = async () => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/teacher/lists`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setTeachers(data["teacherList"]);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching teachers:", error);
        });
    };
    fetchAllTeachers();
  }, []);

  const handleClassTeacherChange = (grade, section, teacherId) => {
    const teacher = teachers.find((teacher) => teacher.id === teacherId);

    const teacherAssignment = {
      assigned_teacher: formatTeacherName(
        teacher?.first_name,
        teacher?.last_name
      ),
      assigned_teacher_id: teacher.id,
    };

    const updateNestedObject = (obj, value) => {
      if (!obj[grade]) {
        obj[grade] = {};
      }
      if (!obj[grade][section]) {
        obj[grade][section] = {};
      }
      obj[grade][section] = value;
      return { ...obj };
    };

    setClassTeachersList(
      updateNestedObject(classTeachersList, teacherAssignment)
    );
    setUpdatedClassTeachers(
      updateNestedObject(updatedClassTeachers, teacherAssignment)
    );
  };

  const formatTeacherName = (firstName, lastName = "") => {
    if (!firstName && !lastName) {
      return "";
    }
    if (!lastName) {
      return firstName.charAt(0).toUpperCase() + firstName.slice(1);
    }
    if (!firstName) {
      return lastName.charAt(0).toUpperCase() + lastName.slice(1);
    }
    return `${firstName.charAt(0).toUpperCase() + firstName.slice(1)} ${
      lastName.charAt(0).toUpperCase() + lastName.slice(1)
    }`;
  };

  const saveClassTeachersUpdate = () => {
    setIsLoading(true);
    const classTeachers = updatedClassTeachers;
    fetch(`${process.env.REACT_APP_API_BASE_URL}/update-class-teachers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ classTeachers }),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        navigate("/class-teachers/list");
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ backgroundColor: "#DDE9F5" }}>
      <Container>
        <Typography variant="h6" component="h2" style={{ padding: 10 }}>
          Class Teacher Assignment
        </Typography>
        <div
          style={{
            backgroundColor: "white",
            marginTop: 5,
            padding: 20,
            borderRadius: 6,
          }}
        >
          {Object.keys(classes).map((grade) => (
            <Grid
              container
              spacing={2}
              key={grade}
              sx={{ margin: 2, width: "100%" }}
            >
              <Typography variant="h6" component="h2">
                Grade {grade}
              </Typography>
              {classes[grade].map((section) => (
                <Grid
                  container
                  spacing={2}
                  sx={{ marginBottom: 2, marginLeft: 2, alignItems: "center" }}
                >
                  <Grid item xs={2}>
                    Section {section}
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Select
                        id="class-teacher-select"
                        value={
                          classTeachersList?.[grade]?.[section]
                            ?.assigned_teacher_id || ""
                        }
                        SelectDisplayProps={{
                          style: { paddingTop: 10, paddingBottom: 10 },
                        }}
                        onChange={(e) => {
                          handleClassTeacherChange(
                            grade,
                            section,
                            e.target.value
                          );
                        }}
                      >
                        {teachers
                          ?.filter((teacher) =>
                            teacher?.grades?.[grade]?.includes(section)
                          )
                          .map((teacher) => {
                            return (
                              <MenuItem key={teacher.id} value={teacher.id}>
                                {formatTeacherName(
                                  teacher?.first_name,
                                  teacher?.last_name
                                )}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" justifyContent="center">
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#00868D" }}
                onClick={saveClassTeachersUpdate}
              >
                Save
              </Button>
              <Button
                onClick={() => navigate("/class-teachers/list")}
                variant="text"
                style={{ color: "black" }}
              >
                Cancel
              </Button>
            </Stack>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

export default EditClassTeachers;
