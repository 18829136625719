import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Axios, { CancelToken } from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./Downloader.css";

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(Number(props.value))}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function DownloadItem(props) {
  const { title, url, method, body, filename, removeFile } = props;
  const delayBeforeRemoving = 1000;
  const [showAlert, setShowAlert] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
  const cancelFileUpload = useRef(null);

  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
      cancelToken: new CancelToken(
        (cancel) => (cancelFileUpload.current = cancel)
      ),
    };

    if (method === "GET") {
      Axios.get(`${url}?timestamp=${Date.now()}`, {
        responseType: "blob",
        ...options,
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        setDownloadInfo((info) => ({
          ...info,
          completed: true,
        }));

        setTimeout(() => {
          removeFile();
        }, delayBeforeRemoving);
      });
    }

    if (method === "POST") {
      Axios.post(`${url}?timestamp=${Date.now()}`, body, {
        responseType: "blob",
        ...options,
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        setDownloadInfo((info) => ({
          ...info,
          completed: true,
        }));
        setTimeout(() => {
          removeFile();
        }, delayBeforeRemoving);
      });
    }
  }, []);

  const handleClick = () => {
    setShowAlert(true);
  };

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;

  const cancelDownload = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current("Request canceled");
      handleClick();
    }
    setTimeout(() => {
      removeFile();
    }, delayBeforeRemoving);
  };

  return (
    <div>
      <li className="list-group-item">
        <div className="row" style={{ display: "block" }}>
          <div className="col-12">
            <div className="d-inline font-weight-bold text-truncate">
              <div>
                <IconButton
                  disabled={downloadInfo.completed}
                  style={{ float: "right" }}
                  color="primary"
                  aria-label="Cancel Download"
                  onClick={() => cancelDownload()}
                >
                  <CancelIcon />
                </IconButton>
                <Typography variant="h6">{title}</Typography>
              </div>
            </div>
            <div className="d-inline ml-2">
              <small>
                {downloadInfo.loaded > 0 && (
                  <>
                    <span className="text-success">
                      {formatBytes(downloadInfo.loaded)}
                    </span>
                    / {formatBytes(downloadInfo.total)}
                  </>
                )}

                {downloadInfo.loaded === 0 && <>Initializing...</>}
              </small>
            </div>
            <div className="d-inline ml-2 ml-auto">
              {downloadInfo.completed && (
                <span className="text-success">Completed</span>
              )}
            </div>
          </div>
          <div className="col-12 mt-2">
            {downloadInfo.loaded > 0 ? (
              <LinearProgressWithLabel value={downloadInfo.progress} />
            ) : (
              <LinearProgress sx={{ width: "92%" }} />
            )}
          </div>
        </div>
      </li>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={showAlert}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Report Downloading Cancelled
        </Alert>
      </Snackbar>
    </div>
  );
}

export default DownloadItem;
