import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { memo, useMemo } from "react";

const defaultTitleStyle = {
  m: 0,
  p: 2,
};

const defaultCloseIconStyle = {
  position: "absolute",
  right: 8,
  top: 8,
  color: "#343a40",
};

const DialogBoxTitle = memo(function DialogBoxTitle(props) {
  const {
    children,
    onClose,
    customTitleStyle,
    customCloseIconStyle,
    ...other
  } = props;

  const titleStyle = useMemo(
    () => customTitleStyle || defaultTitleStyle,
    [customTitleStyle]
  );

  const closeIconStyle = useMemo(
    () => customCloseIconStyle || defaultCloseIconStyle,
    [customCloseIconStyle]
  );

  return (
    <DialogTitle sx={titleStyle} {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} sx={closeIconStyle}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

DialogBoxTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default DialogBoxTitle;
