import React, { useContext, useEffect, useState } from "react";
import { NotebookContext } from "../../Contexts/NotebookContext";
import PuzzleView from "../../ModuleComponents/Puzzle/PuzzleView";
import loadash from "lodash";

function PuzzleCell(props) {
  const {
    notebookData,
    uploadNotebookData,
    isTeachModule,
    isTeacherSeeingStudentWork,
    setShowBadJob,
    setShowGoodJob,
    setSuccessMessage,
    handleButtonDisabled,
    setShowFeedback,
    setLevelLikedFunctionCall,
    setLevelDislikedFunctionCall,
  } = useContext(NotebookContext);
  const { puzzleCellData, cellIndex, notebookType } = props;
  const [currentlyClicked, setCurrentlyClicked] = useState(-1);
  const [disabledSubmitButton, setDisableSubmitButton] = useState(true);
  const [answerChecked, setAnswerChecked] = useState(false);
  const [puzzleData, setPuzzleData] = useState();
  const [showPuzzleExplanation, setShowPuzzleExplantion] = useState(false);
  const options = ["A", "B", "C", "D"];

  useEffect(() => {
    if (notebookData) {
      if (
        notebookData[`${notebookType}_levels`] &&
        notebookData[`${notebookType}_levels`][cellIndex]
      ) {
        const cellData = loadash.cloneDeep(
          notebookData[`${notebookType}_levels`][cellIndex]
        );
        setPuzzleData(cellData);
        setCurrentlyClicked(cellData?.option - 1);
      } else {
        setPuzzleData({
          type: "Puzzle",
          status: "incomplete",
          option: -1,
          result: "",
        });
      }
    }
  }, [notebookData]);

  async function submitAnswer() {
    if (isTeacherSeeingStudentWork) return;
    if (answerChecked) return;
    let puzzleDataCopy = loadash.cloneDeep(puzzleData);
    puzzleDataCopy.option = currentlyClicked + 1;
    puzzleDataCopy.status = "completed";
    puzzleDataCopy.result =
      puzzleCellData?.correct === options[currentlyClicked]
        ? "Correct"
        : "Incorrect";

    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleDataCopy,
      },
    };
    if (puzzleDataCopy.status === "completed" && isTeachModule) {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    let dataUploaded = await uploadNotebookData(uploadData);
    if (!dataUploaded) return;
    setShowFeedback(false);
    if (!puzzleDataCopy?.feedback) {
      setLevelLikedFunctionCall(() => () => {
        handleItemLikedFeedback(puzzleDataCopy);
      });
      setLevelDislikedFunctionCall(
        () => () => handleItemDislikedFeedback(puzzleDataCopy)
      );
      setShowFeedback(true);
    }
    if (puzzleDataCopy.result === "Correct") {
      setSuccessMessage("");
      setShowGoodJob(true);
    } else {
      setShowBadJob(true);
    }

    setShowPuzzleExplantion(true);
    setAnswerChecked(true);
  }

  function displaySolution() {
    setAnswerChecked(true);
    setShowPuzzleExplantion(true);
    puzzleData.option = options.indexOf(puzzleCellData?.correct) + 1;
    puzzleData.status = "completed";
    puzzleData.result = "Correct";
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleData,
      },
    };
    if (puzzleData.status === "completed" && isTeachModule) {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    uploadNotebookData(uploadData);
  }

  async function giveupLevel() {
    let puzzleDataCopy = loadash.cloneDeep(puzzleData);
    puzzleDataCopy["status"] = "completed";
    puzzleDataCopy["giveup"] = 1;
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleDataCopy,
      },
      is_puzzle_level_giveup: 1,
    };
    if (puzzleDataCopy.status === "completed" && isTeachModule) {
      uploadData[[`${notebookType}_puzzle_levels_completed`]] = notebookData[
        `${notebookType}_puzzle_levels_completed`
      ]
        ? notebookData[`${notebookType}_puzzle_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    await uploadNotebookData(uploadData);
  }

  async function handleItemLikedFeedback(levelData) {
    let puzzleDataCopy = loadash.cloneDeep(levelData);
    puzzleDataCopy.feedback = 1;

    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleDataCopy,
      },
    };

    let dataUploaded = await uploadNotebookData(uploadData);
    if (!dataUploaded) return;

    setShowFeedback(false);
  }

  async function handleItemDislikedFeedback(levelData) {
    let puzzleDataCopy = loadash.cloneDeep(levelData);
    puzzleDataCopy.feedback = 0;

    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: puzzleDataCopy,
      },
    };

    let dataUploaded = await uploadNotebookData(uploadData);
    if (!dataUploaded) return;

    setShowFeedback(false);
  }

  return (
    <PuzzleView
      puzzleStrapiData={puzzleCellData}
      puzzleData={puzzleData}
      submitAnswer={submitAnswer}
      isTeachModule={isTeachModule}
      puzzle_type={notebookType}
      displaySolution={displaySolution}
      giveupLevel={giveupLevel}
      answerChecked={answerChecked}
      currentlyClicked={currentlyClicked}
      setCurrentlyClicked={setCurrentlyClicked}
      disabledSubmitButton={disabledSubmitButton || handleButtonDisabled()}
      disabledGiveUpButton={handleButtonDisabled()}
      setDisableSubmitButton={setDisableSubmitButton}
      showPuzzleExplanation={showPuzzleExplanation}
    />
  );
}

export default PuzzleCell;
