import DeleteIcon from "@mui/icons-material/Delete";
import EastIcon from "@mui/icons-material/East";
import RestoreIcon from "@mui/icons-material/Restore";
import WestIcon from "@mui/icons-material/West";
import { Button, Checkbox, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

function StudentWiseMapping(props) {
  const {
    grade,
    section,
    gradeMoveTo,
    sectionMoveTo,
    updateStudentListInFormMappings,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [studentListToRollOver, setStudentListToRollOver] = useState(null);

  useEffect(() => {
    async function fetchPendingStudentList() {
      const requestBody = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          grades: {
            [grade]: [section],
          },
        }),
      };
      let studentListReq = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/student/rollover/pending`,
        requestBody
      );
      let studentList = await studentListReq.json();
      setStudentListToRollOver(studentList);
      setIsLoading(false);
    }
    if (grade && section) {
      setIsLoading(true);
      fetchPendingStudentList();
    }
  }, [grade, section]);

  useEffect(() => {
    function unSelectAllSelectedItemsInMoveTo() {
      if (studentListToRollOver) {
        const updateList = studentListToRollOver[parseInt(grade)][section].map(
          (student) => {
            if (student.isSelectedInMoveTo) {
              delete student.isSelectedInMoveTo;
            }
            return student;
          }
        );
        const mapping = {
          [parseInt(grade)]: {
            [section]: updateList,
          },
        };

        updateStudentListInFormMappings(mapping);
        setStudentListToRollOver(mapping);
      }
    }
    unSelectAllSelectedItemsInMoveTo();
  }, [sectionMoveTo]);

  const handlePendingListSelection = (studentId) => {
    if (studentListToRollOver) {
      const updatedStudentList = studentListToRollOver[parseInt(grade)][
        section
      ].map((student) => {
        if (student.id === studentId && !student.delete) {
          student.isSelectedInMoveFrom = !student.isSelectedInMoveFrom;
        }
        return student;
      });

      const mapping = {
        [parseInt(grade)]: {
          [section]: updatedStudentList,
        },
      };

      setStudentListToRollOver(mapping);
    }
  };

  const handleMoveToNextYearClass = () => {
    if (studentListToRollOver) {
      const updateList = studentListToRollOver[parseInt(grade)][section].map(
        (student) => {
          if (student.isSelectedInMoveFrom) {
            delete student.isSelectedInMoveFrom;
            student.pending = false;
            student.moveToGrade = gradeMoveTo;
            student.moveToSection = sectionMoveTo;
          }
          return student;
        }
      );
      const mapping = {
        [parseInt(grade)]: {
          [section]: updateList,
        },
      };

      updateStudentListInFormMappings(mapping);
      setStudentListToRollOver(mapping);
    }
  };

  const handleMovedStudentSelection = (studentId) => {
    if (studentListToRollOver) {
      const updateList = studentListToRollOver[parseInt(grade)][section].map(
        (student) => {
          if (student.id === studentId) {
            student.isSelectedInMoveTo = !student.isSelectedInMoveTo;
          }
          return student;
        }
      );
      const mapping = {
        [parseInt(grade)]: {
          [section]: updateList,
        },
      };

      setStudentListToRollOver(mapping);
    }
  };

  const handleUndoMovedStudent = () => {
    if (studentListToRollOver) {
      const updateList = studentListToRollOver[parseInt(grade)][section].map(
        (student) => {
          if (student.isSelectedInMoveTo) {
            delete student.isSelectedInMoveTo;
            student.pending = true;
            delete student.moveToGrade;
            delete student.moveToSection;
          }
          return student;
        }
      );
      const mapping = {
        [parseInt(grade)]: {
          [section]: updateList,
        },
      };

      updateStudentListInFormMappings(mapping);
      setStudentListToRollOver(mapping);
    }
  };

  const handleDeleteStudents = () => {
    if (studentListToRollOver) {
      const updateList = studentListToRollOver[parseInt(grade)][section].map(
        (student) => {
          if (student.isSelectedInMoveFrom) {
            delete student.isSelectedInMoveFrom;
            student.pending = false;
            student.delete = true;
          }
          return student;
        }
      );
      const mapping = {
        [parseInt(grade)]: {
          [section]: updateList,
        },
      };

      updateStudentListInFormMappings(mapping);
      setStudentListToRollOver(mapping);
    }
  };

  const handleRestoreDeletedStudent = (studentId) => {
    if (studentListToRollOver) {
      const updateList = studentListToRollOver[parseInt(grade)][section].map(
        (student) => {
          if (student.id === studentId) {
            student.pending = true;
            student.delete = false;
          }
          return student;
        }
      );
      const mapping = {
        [parseInt(grade)]: {
          [section]: updateList,
        },
      };

      updateStudentListInFormMappings(mapping);
      setStudentListToRollOver(mapping);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Grid
      container
      sx={{
        marginTop: 2,
        marginBottom: 2,
      }}
    >
      <Grid
        item
        xs={5}
        sx={{
          border: 1,
          borderColor: "grey.500",
          borderRadius: 2,
          backgroundColor: "grey.100",
          padding: 2,
        }}
      >
        {studentListToRollOver[parseInt(grade)][section]?.map((student) => {
          if (student?.pending || student?.delete) {
            return (
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={student?.isSelectedInMoveFrom || false}
                  onChange={() => handlePendingListSelection(student.id)}
                  indeterminate={student?.delete}
                  disabled={student?.delete}
                  sx={{
                    padding: 0,
                    margin: 0,
                  }}
                />
                {student.name}
                {student.delete && (
                  <Button
                    variant="contained"
                    onClick={() => handleRestoreDeletedStudent(student.id)}
                    sx={{
                      backgroundColor: "#00868D",
                      padding: 0,
                      margin: 0,
                      minWidth: 0,
                      marginLeft: 1,
                      "&.MuiButton-root:hover": { backgroundColor: "#00868D" },
                    }}
                  >
                    <RestoreIcon sx={{ height: "2vh" }} />
                  </Button>
                )}
              </p>
            );
          }
        })}
      </Grid>
      <Grid
        item
        xs={1}
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Button
          variant="contained"
          onClick={handleMoveToNextYearClass}
          sx={{
            marginBottom: 1,
            backgroundColor: "#00868D",
            "&.MuiButton-root:hover": { backgroundColor: "#00868D" },
          }}
        >
          <EastIcon />
        </Button>
        <Button
          variant="contained"
          onClick={handleUndoMovedStudent}
          sx={{
            marginBottom: 1,
            backgroundColor: "#00868D",
            "&.MuiButton-root:hover": { backgroundColor: "#00868D" },
          }}
        >
          <WestIcon />
        </Button>
        <Button
          variant="contained"
          onClick={handleDeleteStudents}
          sx={{
            marginBottom: 1,
            backgroundColor: "#00868D",
            "&.MuiButton-root:hover": { backgroundColor: "#00868D" },
          }}
        >
          <DeleteIcon />
        </Button>
      </Grid>
      <Grid
        item
        xs={5}
        sx={{
          border: 1,
          borderColor: "grey.500",
          borderRadius: 2,
          backgroundColor: "grey.100",
          padding: 2,
        }}
      >
        {sectionMoveTo &&
          studentListToRollOver[parseInt(grade)][section]?.map((student) => {
            if (
              student?.moveToGrade === gradeMoveTo &&
              student?.moveToSection === sectionMoveTo
            )
              return (
                <p style={{ padding: 0, margin: 0 }}>
                  <Checkbox
                    checked={student?.isSelectedInMoveTo || false}
                    onChange={() => handleMovedStudentSelection(student.id)}
                    sx={{
                      padding: 0,
                      margin: 0,
                    }}
                  />
                  {student.name}
                </p>
              );
          })}
      </Grid>
    </Grid>
  );
}

export default StudentWiseMapping;
