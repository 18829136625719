import {
  Box,
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  FormControl,
  IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "../../Components/Tooltips/Tooltip";
function AppControls() {
  const [isLoading, setIsLoading] = useState(true);
  const [appConfig, setAppConfig] = useState({});
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    const fetchAppConfig = async () => {
      fetch("/app-config")
        .then((res) => res.json())
        .then((data) => {
          setAppConfig(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching app config:", error);
          setIsLoading(false);
        });
    };
    fetchAppConfig();
  }, []);

  const formatDateToHTTP = (date) => {
    if (!date) return null;
    return date.toUTCString();
  };

  const handleAppConfigChange = (name, value) => {
    setDisableSave(false);
    if (name === "rollover_start_date" || name === "rollover_end_date") {
      value = formatDateToHTTP(value);
    }
    setAppConfig({ ...appConfig, [name]: value });
  };

  const handleConfigSubmit = () => {
    setDisableSave(true);
    setIsLoading(true);
    fetch("/update-app-config", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(appConfig),
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setDisableSave(true);
      })
      .catch((error) => {
        console.error("Error updating app config:", error);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Container>
        <div>
          <Typography variant="h6" component="h2" style={{ padding: 10 }}>
            App Controls
          </Typography>
        </div>
        <Box
          className="app-controls-box"
          sx={{
            backgroundColor: "#ffffff",
            padding: "5px 10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: "#00868D" }}>
                School Roll Over
              </Typography>
            </Grid>
            <Grid item xs={10} container spacing={2}>
              <Grid item xs={4}>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      openTo="month"
                      views={["month", "day"]}
                      inputFormat="dd/MM"
                      value={
                        appConfig?.rollover_start_date
                          ? new Date(appConfig.rollover_start_date)
                          : null
                      }
                      renderInput={(props) => <TextField {...props} />}
                      onChange={(newValue) => {
                        handleAppConfigChange("rollover_start_date", newValue);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    openTo="month"
                    views={["month", "day"]}
                    inputFormat="dd/MM"
                    value={
                      appConfig?.rollover_end_date
                        ? new Date(appConfig.rollover_end_date)
                        : null
                    }
                    renderInput={(props) => <TextField {...props} />}
                    onChange={(newValue) => {
                      handleAppConfigChange("rollover_end_date", newValue);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" style={{ color: "#00868D" }}>
                Student Reports
              </Typography>
              <Tooltip title="Hide or show the button to download student report card and certificate">
                <IconButton sx={{ marginRight: 10 }}>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    checked={!!appConfig?.allow_student_report_card_download}
                    onChange={(e) => {
                      handleAppConfigChange(
                        "allow_student_report_card_download",
                        e.target.checked
                      );
                    }}
                  />
                }
                sx={{ margin: 0 }}
              />
            </Grid>
            <Grid item xs={12} align={"center"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfigSubmit}
                style={{ backgroundColor: "#00868D" }}
                disabled={disableSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}

export default AppControls;
