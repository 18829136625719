import React, { useContext, useEffect, useRef, useState } from "react";
import Game from "@smaskar/gameslib";
import loadash from "lodash";
import { NotebookContext } from "../../Contexts/NotebookContext";
import Arrow_down_white from "../../../Assets/Images/Arrow_down_white.svg";
import Arrow_left_white from "../../../Assets/Images/Arrow_left_white.svg";
import Arrow_right_white from "../../../Assets/Images/Arrow_right_white.svg";
import Arrow_up_white from "../../../Assets/Images/Arrow_up_white.svg";
import CheetiRight from "../../../Assets/Images/CheetiRight.svg";
import { authUserContext } from "../../Contexts/AuthUser";
import { calculateHeight } from "../../../Utils/helper";

function arrow_keys_handler(e) {
  if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
    e.preventDefault();
  }
}

function GameCell(props) {
  const { user } = useContext(authUserContext);
  const {
    notebookData,
    uploadNotebookData,
    isTeachModule,
    currentlyRunningLevel,
    setCurrentlyRunningLevel,
    setErrorMessage,
    setShowErrorBox,
    setSuccessMessage,
    setShowGoodJob,
    handleButtonDisabled,
    setShowFeedback,
    setLevelLikedFunctionCall,
    setLevelDislikedFunctionCall,
  } = useContext(NotebookContext);
  const { gameSetData, cellIndex, notebookType, resetState, csvDataFiles } =
    props;
  const toolbox = `${notebookType}_toolbox_${cellIndex}`;
  const blocklyArea = `${notebookType}_blocklyArea_${cellIndex}`;
  const blockly = `${notebookType}_blockly_${cellIndex}`;
  const canvas = `${notebookType}_canvas_${cellIndex}`;
  const [levelInfo, setLevelInfo] = useState();
  const [configFile, setConfigFile] = useState();
  const [cellGameInfo, setCellGameInfo] = useState();
  const [game, setGame] = useState();
  const [gameSetupCompleted, setGameSetupCompleted] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Run Code");
  const [gameLoaded, setGameLoaded] = useState(false);
  const [gameData, setGameData] = useState();
  const [intructionBoxHeight, setInstructionBoxHeight] = useState(64.6262);
  const [showSkipLevelButton, setShowSkipLevelButton] = useState(false);
  const [isModelBasedLevel, setIsModelBasedLevel] = useState(false);
  const [gameCellDataLoaded, setGameCellDataLoaded] = useState(false);
  const notebookGameInstructionRef = useRef(null);

  useEffect(() => {
    // Initial height calculation after the component mounts and CSS is applied
    calculateHeight(notebookGameInstructionRef, setInstructionBoxHeight);

    // Recalculate height on window resize
    window.addEventListener("resize", onResizeWindow);
    async function createGameObject() {
      const elementIds = {
        toolbox: toolbox,
        blocklyArea: blocklyArea,
        blockly: blockly,
        canvas: canvas,
      };
      const gameInfo = await Promise.resolve(
        filterGameData(gameSetData, elementIds)
      );
      setIsModelBasedLevel(gameInfo?.options?.blockly?.modelExtension);
      setCellGameInfo(gameInfo);
      setLevelInfo(gameInfo.level);
      let csvFiles = [];
      for (let key of Object.keys(csvDataFiles)) {
        if (key < cellIndex) {
          csvFiles.push(csvDataFiles[key]);
        }
      }
      setGame(
        new Game(gameInfo?.gameType, JSON.parse(JSON.stringify(csvFiles)))
      );
      const jsonFile = await fetch(gameInfo.level.configFile);
      const jsonData = await jsonFile.json();
      setConfigFile(jsonData);
    }
    createGameObject();
    return () => {
      window.removeEventListener("resize", onResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (game) {
      game.setup(cellGameInfo?.options, false);
      game.loadBlocklyImgs(cellGameInfo?.blocklyAsset);
      game.loadAssets(cellGameInfo?.assets);
      setGameSetupCompleted(true);
    }
    return () => {
      game?.clearGameWindow();
    };
  }, [game]);

  useEffect(() => {
    if (gameSetupCompleted && configFile) {
      game.loadLevel(configFile);
      setGameLoaded(true);
    }
  }, [gameSetupCompleted, configFile]);

  useEffect(() => {
    const handleCellData = async (cellData) => {
      if (notebookType === "assessment") {
        if (cellData.status !== "completed" && cellData.attempts >= 10) {
          setShowSkipLevelButton(true);
        }
      }

      setGameData(cellData);

      if (isModelBasedLevel && cellData?.trainingData) {
        await game.setTrainingData(cellData.trainingData);
        await game.loadModel(cellData.model);
      }

      if (
        cellData?.giveup ||
        (notebookType === "exercise" && gameSetData?.level?.demoLevel)
      ) {
        game.loadSolution();
      } else {
        game.loadCode(cellData.code);
      }
    };

    const handleEmptyCellData = () => {
      if (notebookType === "exercise" && gameSetData?.level?.demoLevel) {
        game.loadSolution();
      }

      setGameData({
        status: "incomplete",
        attempts: 0,
        type: "Game",
        code: "",
      });
    };

    if (notebookData && gameLoaded) {
      const levelData = notebookData[`${notebookType}_levels`]?.[cellIndex];

      if (levelData) {
        handleCellData(levelData);
      } else {
        handleEmptyCellData();
      }
      setGameCellDataLoaded(true);
    }
  }, [gameLoaded]);

  useEffect(() => {
    // Additional height calculation when instruction content changes
    calculateHeight(notebookGameInstructionRef, setInstructionBoxHeight);
  }, [notebookGameInstructionRef.current?.clientHeight]);

  useEffect(() => {
    if (currentlyRunningLevel !== cellIndex) {
      if (submitButtonText === "Reset") {
        game.restart();
        setSubmitButtonText("Run Code");
      }
    }
  }, [currentlyRunningLevel]);

  useEffect(() => {
    if (notebookData && gameCellDataLoaded) {
      const levelData = notebookData[`${notebookType}_levels`]?.[cellIndex];
      if (levelData) {
        setGameData(levelData);
      }
    }
  }, [notebookData]);

  const onResizeWindow = () => {
    calculateHeight(notebookGameInstructionRef, setInstructionBoxHeight);
  };

  const uploadGameData = async (data) => {
    let uploadData = {
      [`${notebookType}_levels`]: {
        [cellIndex]: data,
      },
    };
    if (data.status === "completed" && isTeachModule) {
      uploadData[[`${notebookType}_game_levels_completed`]] = notebookData[
        `${notebookType}_game_levels_completed`
      ]
        ? notebookData[`${notebookType}_game_levels_completed`] + 1
        : 1;
      uploadData[[`${notebookType}_levels_completed`]] = notebookData[
        `${notebookType}_levels_completed`
      ]
        ? notebookData[`${notebookType}_levels_completed`] + 1
        : 1;
    }
    if ("giveup" in data && data["giveup"]) {
      uploadData["is_game_level_giveup"] = 1;
    }
    return await uploadNotebookData(uploadData);
  };

  async function runCode() {
    let levelData = loadash.cloneDeep(gameData);
    if (levelData.status === "incomplete") {
      levelData.attempts++;
      levelData.code = game.fetchCode();
      if (isModelBasedLevel) {
        levelData.model = await game.saveModel(
          `localstorage://model/level/${cellIndex}`
        );
        levelData.trainingData = await game.getTrainingData();
      }
    }
    game.setFailure(async (errorCode) => {
      if (
        notebookType === "assessment" &&
        levelData.status !== "completed" &&
        levelData.attempts >= 10
      ) {
        setShowSkipLevelButton(true);
      }
      let statusUpdate = true;
      if (levelData.status === "incomplete") {
        statusUpdate = await uploadGameData(levelData);
      }
      if (statusUpdate) {
        for (const item of cellGameInfo?.errorMessages) {
          if (item.Code === errorCode) {
            setErrorMessage(item.Message);
            setShowErrorBox(true);
            break;
          }
        }
      }
      window.removeEventListener("keydown", arrow_keys_handler, false);
    });
    game.setSuccess(async () => {
      setShowFeedback(false);
      let statusUpdated = true;
      if (levelData.status === "incomplete") {
        levelData.status = "completed";
        statusUpdated = await uploadGameData(levelData);
      }
      const x = cellGameInfo?.successMessages.length;
      setLevelLikedFunctionCall(() => () => handleItemLikedFeedback(levelData));
      setLevelDislikedFunctionCall(
        () => () => handleItemDislikedFeedback(levelData)
      );
      if (!levelData?.feedback) {
        setShowFeedback(true);
      }
      if (x > 0) {
        const msg =
          cellGameInfo?.successMessages[Math.floor(Math.random() * x)];
        if (msg === "") {
          msg = cellGameInfo?.successMessages[0];
        }
        setSuccessMessage(msg);
        if (statusUpdated) {
          setShowGoodJob(true);
        }
      } else {
        setSuccessMessage(" ");
        if (statusUpdated) {
          setShowGoodJob(true);
        }
      }
      window.removeEventListener("keydown", arrow_keys_handler, false);
    });
    game.runCode();
    if (levelData.status === "incomplete") uploadGameData(levelData);
  }

  function handleRunRestartButtonClick() {
    if (submitButtonText === "Run Code") {
      setCurrentlyRunningLevel(cellIndex);
      window.addEventListener("keydown", arrow_keys_handler, false);
      runCode();
      setSubmitButtonText("Reset");
    } else {
      setCurrentlyRunningLevel(null);
      window.removeEventListener("keydown", arrow_keys_handler, false);
      game.restart();
      setSubmitButtonText("Run Code");
    }
  }

  async function giveupLevel() {
    let levelData = loadash.cloneDeep(gameData);
    if (levelData?.status === "completed") return;
    levelData["status"] = "completed";
    levelData["giveup"] = 1;
    let uploadStatus = await uploadGameData(levelData);
    if (!uploadStatus) return;
    game.loadSolution();
    setShowSkipLevelButton(false);
  }

  async function handleItemLikedFeedback(levelData) {
    let data = loadash.cloneDeep(levelData);
    data["feedback"] = 1;
    let uploadStatus = await uploadGameData(data);
    if (!uploadStatus) return;
    setShowFeedback(false);
  }

  async function handleItemDislikedFeedback(levelData) {
    let data = loadash.cloneDeep(levelData);
    data["feedback"] = 0;
    let uploadStatus = await uploadGameData(data);
    if (!uploadStatus) return;
    setShowFeedback(false);
  }

  return (
    <div className="game-cell" id={`game-cell-${cellIndex}`}>
      <div
        className="notebook-game-instructions-box"
        style={{ height: `${intructionBoxHeight}px` }}
      >
        <img src={CheetiRight} alt="" />
        <p id="notebook-game-instructions" ref={notebookGameInstructionRef}>
          {gameSetData?.level?.instructions}
        </p>
      </div>
      <div id="notebook-game-view">
        <div className="notebook-game-content-wrapper">
          <div id={canvas} />{" "}
          <div
            id={blocklyArea}
            style={{ height: "400px", width: "calc(100% - 400px)" }}
          >
            <div id={blockly} style={{ height: "30vw", width: "47vw" }} />
          </div>
          <xml id={toolbox} style={{ display: "none" }} />
        </div>
        <div className="notebook-game-action-button-container d-flex mt-1">
          <div
            className="notebook-game-action-buttons"
            style={{ marginLeft: 0 }}
          >
            <button
              id="run-restart-button"
              onClick={handleRunRestartButtonClick}
              style={
                submitButtonText === "Reset"
                  ? { backgroundColor: "#FFB017", lineHeight: "2.2vw" }
                  : { lineHeight: "2.2vw" }
              }
              disabled={handleButtonDisabled()}
            >
              {submitButtonText}
            </button>
            {cellGameInfo?.arrowButtons && (
              <>
                <button
                  id="game-up"
                  onClick={() => {
                    game.keyClicked("up");
                  }}
                  disabled={handleButtonDisabled()}
                >
                  <img src={Arrow_up_white} />
                </button>
                <button
                  id="game-down"
                  onClick={() => {
                    game.keyClicked("down");
                  }}
                  disabled={handleButtonDisabled()}
                >
                  <img src={Arrow_down_white} />
                </button>
                <button
                  id="game-left"
                  onClick={() => {
                    game.keyClicked("left");
                  }}
                  disabled={handleButtonDisabled()}
                >
                  <img src={Arrow_left_white} />
                </button>
                <button
                  id="game-right"
                  onClick={() => {
                    game.keyClicked("right");
                  }}
                  disabled={handleButtonDisabled()}
                >
                  <img src={Arrow_right_white} />
                </button>
              </>
            )}
          </div>
          {isTeachModule && (
            <div
              className="show-notebook-game-solution-btn"
              style={cellGameInfo?.arrowButtons ? { height: "3.8vw" } : {}}
            >
              <button
                id="show-solution"
                className="d-flex align-item align-items-center justify-content-center"
                style={{
                  margin: "0px",
                  fontSize: "1.5vw",
                  lineHeight: "2.2vw",
                }}
                onClick={() => game.loadCode(configFile.solution)}
                disabled={handleButtonDisabled()}
              >
                Show Solution
              </button>
            </div>
          )}
          {user?.role?.includes("student") && showSkipLevelButton && (
            <button
              className="giveup-button ml-auto"
              onClick={giveupLevel}
              disabled={handleButtonDisabled()}
            >
              Skip Level
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default GameCell;

function filterGameData(gameObjInfo, elementIds) {
  let renderer;
  let categoriesInToolbox;
  let blocklyObjOption;
  let gameObjOption;
  let options;
  const blocklyAsset = {};
  const level = gameObjInfo.level;
  const gameType = gameObjInfo["gameType"]["name"];
  const videoUrl = gameObjInfo["Demo Video"];
  const structure = gameObjInfo.structure;
  const { blocklyImages } = gameObjInfo;
  const errorMessages = gameObjInfo["gameType"]["errorMessages"];
  const successMessages = gameObjInfo["gameType"]["successMessages"];
  const arrowButtons = gameObjInfo["gameType"].arrowButtons;
  if (structure === "codingEnvironment") {
    renderer = gameObjInfo["configOptions"].Renderer;
    renderer = renderer.toLowerCase();
    categoriesInToolbox = gameObjInfo["configOptions"]["CategoriesInToolbox"];
    blocklyObjOption = {
      toolboxRef: elementIds.toolbox,
      blocklyAreaRef: elementIds.blocklyArea,
      blocklyRef: elementIds.blockly,
      renderer,
      categoriesInToolbox,
      modelExtension: gameObjInfo?.configOptions?.ModelExtension ?? false,
    };
    gameObjOption = {
      canvas: elementIds.canvas,
    };
    // Load the options
    options = {
      structure,
      blockly: blocklyObjOption,
      game: gameObjOption,
    };
  } else {
    blocklyObjOption = {
      blocklyAreaRef: elementIds.blocklyArea,
      blocklyRef: elementIds.blockly,
    };
    // Load the options
    options = {
      structure,
      blockly: blocklyObjOption,
    };
  }
  if (blocklyImages.length >= 0) {
    // extract key
    for (const imagePath of blocklyImages) {
      const imagePathComponents = imagePath.split("/");
      let imageKey = imagePathComponents[5];
      if (imageKey.includes("_")) {
        const imageKeyComponents = imageKey.split("_");
        imageKey = "";
        for (let i = 0; i < imageKeyComponents.length - 1; i++) {
          imageKey += imageKeyComponents[i];
        }
      }
      blocklyAsset[imageKey] = imagePath;
    }
  }
  // Create game graphics
  const gameAssets = gameObjInfo["gameType"].graphics;
  const gameSounds = gameObjInfo["gameType"].sounds;
  const assets = {
    sounds: {},
  };
  for (const imagePath of gameAssets) {
    const imagePathComponents = imagePath.split("/");
    let imageKey = imagePathComponents[5];
    if (imageKey.includes("_")) {
      const imageKeyComponents = imageKey.split("_");
      imageKey = "";
      for (let i = 0; i < imageKeyComponents.length - 1; i++) {
        imageKey += imageKeyComponents[i];
      }
    }
    assets[imageKey] = imagePath;
  }
  if (structure === "playgroundEnvironment") {
    delete assets.sounds;
  } else {
    for (const soundPath of gameSounds) {
      const soundPathComponents = soundPath.split("/");
      let soundKey = soundPathComponents[5];
      if (soundKey.includes("_")) {
        const soundKeyComponents = soundKey.split("_");
        soundKey = "";
        for (let i = 0; i < soundKeyComponents.length - 1; i++) {
          soundKey += soundKeyComponents[i];
        }
      }
      assets.sounds[soundKey] = soundPath;
    }
  }
  return {
    assets,
    level,
    options,
    gameType,
    videoUrl,
    blocklyAsset,
    errorMessages,
    successMessages,
    arrowButtons,
  };
}
