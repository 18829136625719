import {
  Box, CircularProgress, Container, Grid, Typography,
} from '@mui/material';
import {
  React, useContext, useEffect, useRef, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import AssesmentGamePythonPerformance from '../../Components/Charts/AssesmentGamePythonPerformance';
import AssesmentProgress from '../../Components/Charts/AssesmentProgress';
import AssesmentPuzzlePerformance from '../../Components/Charts/AssesmentPuzzlePerformance';
import GameLevelProgress from '../../Components/Charts/GameLevelProgress';
import GamePerformance from '../../Components/Charts/GamePerformance';
import PuzzleLevelProgress from '../../Components/Charts/PuzzleLevelProgress';
import PuzzlePerformance from '../../Components/Charts/PuzzlePerformance';
import { authUserContext } from '../../Components/Contexts/AuthUser';
import '../../index.css';
import PythonPerformance from '../../Components/Charts/PythonPerformance';
import PythonLevelProgress from '../../Components/Charts/PythonLevelProgress';

export default function ModuleAnalytics(props) {
  document.title = "CSSoch | Module Analytics";
  const [isLoading, setisLoading] = useState(false);
  const [isLoader, setisLoader] = useState(true);
  const [modules, setModules] = useState([]);
  const [moduleNotAssigned, setModuleNotAssigned] = useState();
  const [gameLevelData, setGameLevelData] = useState({});
  const [gamePerformanceData, setGamePerformanceData] = useState({});
  const [pythonLevelData, setPythonLevelData] = useState({});
  const [pythonPerformanceData, setPythonPerformanceData] = useState({});
  const [puzzleLevelProgressData, setPuzzleLevelProgressData] = useState({});
  const [puzzlePerformanceData, setPuzzlePerformanceData] = useState({});
  const [assesmentProgressData, setAssesmentProgressData] = useState({});
  const [assesmentGamePerformanceData, setAssesmentGamePerformanceData] = useState({});
  const [assesmentPythonPerformanceData, setAssesmentPythonPerformanceData] = useState({});
  const [assesmentPuzzlePerformanceData, setAssesmentPuzzlePerformanceData] = useState({});
  const [selectedModule, setSelectedModule] = useState();
  const [selectedModuleType, setSelectedModuleType] = useState();
  const [isModuleFetched, setisModuleFetched] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState(0);
  const [moduleBarWidth, setModuleBarWidth] = useState();
  const [puzzlePieLegend, setPuzzlePieLegend] = useState(false);
  const [assesmentPieLegend, setAssesmentPieLegend] = useState(false);
  const {user} = useContext(authUserContext);
  let analyticsData = {};
  const location = useLocation();
  const schoolcode = location.state ? location.state.schoolcode : user.schoolcode;
  const [game, setGame] = useState();
  const [python, setPython] = useState();
  const [puzzle, setPuzzle] = useState();
  const [assesment, setAssesment] = useState();
  const [allSectionData, setAllSectionData] = useState();
  const [moduleWiseAllSectionData, setModuleWiseAllSectionData] = useState();
  const [gradeWiseData, setGradeWiseData] = useState(JSON.parse(sessionStorage.getItem('module_analytics_data'))?.hasOwnProperty(schoolcode) ? JSON.parse(sessionStorage.getItem('module_analytics_data'))[schoolcode] : {});
  const dataFetchedRef = useRef(true);
  const [moduleAssignmentData, setModuleAssignmentData] = useState([]);
  const [reRenderGraphs, setRerenderGraphs] = useState(true);
  const grade = useRef(props.grade);
  const moduleId = useRef();
  const selectedSections = useRef([]);

  const [gradeChanged, setGradeChanged] = useState();
  useEffect(() => {
    grade.current = props.grade;
    selectedSections.current = props.selectedSections;
    setisLoader(true);
    setisModuleFetched(false);
    setModules();
    setSelectedModule();
    setSelectedModuleType();
    setModuleAssignmentData([]);
    setRerenderGraphs((prev) => !prev);
    setGradeChanged((prev) => !prev);
  }, [props.grade]);
  useEffect(() => {
    if (dataFetchedRef.current) {
      dataFetchedRef.current = false;
      return;
    }
    if (gradeWiseData && gradeWiseData[props.grade]) {
      setModuleWiseAllSectionData(gradeWiseData[props.grade]);
      const data = gradeWiseData[props.grade].moduleNames;
      const assignmentData = gradeWiseData[props.grade].moduleAssignment;
      const modulesAndNotebooks = [...data.modules, ...data.notebooks];
      const sectionAssignmentData = new Array(modulesAndNotebooks.length).fill('notassigned');
      let lastAssignedModule = 0;
      sectionAssignmentData.map((value, index) => {
        let assignedCount = 0;
        props.selectedSections.map((sec) => {
          if (assignmentData[sec][index] === 'assigned') assignedCount++;
        });
        if (assignedCount === props.selectedSections.length) sectionAssignmentData[index] = 'assigned';
      });
      sectionAssignmentData.map((value, index) => {
        if (value === 'assigned') lastAssignedModule = index;
      });
      setModuleAssignmentData(sectionAssignmentData);
      setModules(modulesAndNotebooks);
      setSelectedModule(modulesAndNotebooks[lastAssignedModule][1]);
      setSelectedModuleType(lastAssignedModule < data.modules.length ? 'module' : 'notebook');
      setSelectedModuleId(lastAssignedModule);
      moduleId.current = modulesAndNotebooks[lastAssignedModule][1];
      const module_bar_width = 89 / (modulesAndNotebooks.length);
      setModuleBarWidth(module_bar_width);
      setisLoading(true);
      setisModuleFetched(true);
    } else {
      let lastAssignedModule = 0;
      setModuleWiseAllSectionData();
      fetch(`${process.env.REACT_APP_API_BASE_URL}/module-names/${schoolcode}/${props.grade}`).then((res) => res.json()).then((data) => {
        const { assignmentData } = data;
        const modulesAndNotebooks = [...data.modules, ...data.notebooks];
        const sectionAssignmentData = new Array(modulesAndNotebooks.length).fill('notassigned');
        sectionAssignmentData.map((value, index) => {
          let assignedCount = 0;
          selectedSections.current.map((sec) => {
            if (assignmentData[sec][index] === 'assigned') assignedCount++;
          });
          if (assignedCount === selectedSections.current.length) sectionAssignmentData[index] = 'assigned';
        });
        setModuleAssignmentData(sectionAssignmentData);
        sectionAssignmentData.map((value, index) => {
          if (value === 'assigned') lastAssignedModule = index;
        });
        setSelectedModule(modulesAndNotebooks[lastAssignedModule][1]);
        setSelectedModuleType(lastAssignedModule < data.modules.length ? 'module' : 'notebook');
        setSelectedModuleId(lastAssignedModule);
        moduleId.current = modulesAndNotebooks[lastAssignedModule][1];
        setGradeWiseData((currentValue) => {
          if (currentValue) {
            if (currentValue[props.grade]) {
              currentValue[props.grade].moduleNames = {
                modules: data.modules,
                notebooks: data.notebooks
              };
              currentValue[props.grade].moduleAssignment = assignmentData;
              return currentValue;
            }
            currentValue[props.grade] = {};
            currentValue[props.grade].moduleNames = {
              modules: data.modules,
              notebooks: data.notebooks
            };
            currentValue[props.grade].moduleAssignment = assignmentData;
            return currentValue;
          }
          const newValue = {};
          newValue[props.grade] = {
            moduleNames: {
              modules: data.modules,
              notebooks: data.notebooks
            },
          };
          currentValue[props.grade].moduleAssignment = assignmentData;
          return newValue;
        });
        const module_analytics_data = JSON.parse(sessionStorage.getItem('module_analytics_data'));
        if (!module_analytics_data.hasOwnProperty(schoolcode)) {
          module_analytics_data[schoolcode] = {};
        }
        if (module_analytics_data[schoolcode][props.grade]) {
          module_analytics_data[schoolcode][props.grade].moduleNames = {
            modules: data.modules,
            notebooks: data.notebooks
          };
          module_analytics_data[schoolcode][props.grade].moduleAssignment = assignmentData;
        } else {
          module_analytics_data[schoolcode][props.grade] = {};
          module_analytics_data[schoolcode][props.grade].moduleNames = {
            modules: data.modules,
            notebooks: data.notebooks
          };
          module_analytics_data[schoolcode][props.grade].moduleAssignment = assignmentData;
        }
        sessionStorage.setItem('module_analytics_data', JSON.stringify(module_analytics_data));
        setModules([...data.modules, ...data.notebooks]);
        const module_bar_width = 89 / (data.modules.length + data.notebooks.length);
        setModuleBarWidth(module_bar_width);
        setisLoading(true);
        setisModuleFetched(true);
      });
    }
  }, [gradeChanged]);

  const [moduleRefresh, setModuleRefresh] = useState(true);
  useEffect(() => {
    setAllSectionData();
    setModuleNotAssigned();
    setGameLevelData({});
    setGamePerformanceData({});
    setPythonLevelData({});
    setPythonPerformanceData({});
    setPuzzleLevelProgressData({});
    setPuzzlePerformanceData({});
    setAssesmentProgressData({});
    setAssesmentGamePerformanceData({});
    setAssesmentPythonPerformanceData({});
    setAssesmentPuzzlePerformanceData({});
    setPuzzlePieLegend(false);
    setAssesmentPieLegend(false);
    setisLoader(true);
    setisLoading(true);
    setRerenderGraphs((prev) => !prev);
    setModuleRefresh((prev) => !prev);
  }, [selectedModule, isModuleFetched]);

  useEffect(() => {
    const allSectionAnalyticsData = {};
    const currentModuleAllSectionData = {};
    if (selectedModule && isModuleFetched) {
      let moduleNotAssignedToSectionsCount = 0;
      if (moduleWiseAllSectionData && moduleWiseAllSectionData[selectedModuleType] && moduleWiseAllSectionData[selectedModuleType][selectedModule]) {
        Object.keys(moduleWiseAllSectionData[selectedModuleType][selectedModule]).map((section) => {
          const data = moduleWiseAllSectionData[selectedModuleType][selectedModule][section];
          if (selectedSections.current.includes(section)) {
            analyticsData[section] = data;
            allSectionAnalyticsData[section] = data;
            if (data.status === 'notAssigned') moduleNotAssignedToSectionsCount++;
            else {
              if(data.GamePuzzleNames.game)
                setGame(data.GamePuzzleNames.game);
              if(data.GamePuzzleNames.python)
                setPython(data.GamePuzzleNames.python)
              if(data.GamePuzzleNames.puzzle)
                setPuzzle(data.GamePuzzleNames.puzzle);
              if(data.GamePuzzleNames.assessment)
                setAssesment(data.GamePuzzleNames.assessment);
            }
          } else {
            allSectionAnalyticsData[section] = data;
          }
          if (Object.keys(analyticsData).length === selectedSections.current.length) {
            if (moduleNotAssignedToSectionsCount === selectedSections.current.length) {
              setModuleNotAssigned(true);
              setisLoader(false);
            } else {
              setModuleNotAssigned(false);
              createGraphsData();
            }
          }
          if (Object.keys(allSectionAnalyticsData).length === props.gradeData[props.grade].length) {
            setAllSectionData(allSectionAnalyticsData);
          }
        });
      } else {
        props.gradeData[props.grade].map(async(section) => {
          let data = {status : 'notAssigned'};
          if(gradeWiseData[props.grade].moduleAssignment){
            if(gradeWiseData[props.grade].moduleAssignment[section][selectedModuleId]==='assigned'){
              data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/module-analytics/${schoolcode}/${selectedModuleType}/${selectedModule}/${props.grade}/${section}`).then((res) => res.json())
            }
          }
          if (selectedSections.current.includes(section)) {
            analyticsData[section] = data;
            allSectionAnalyticsData[section] = data;
            if (data.status === 'notAssigned') moduleNotAssignedToSectionsCount++;
            else {
              if(data.GamePuzzleNames.game)
                setGame(data?.GamePuzzleNames?.game);
              if(data.GamePuzzleNames.python)
                setPython(data.GamePuzzleNames.python)
              if(data.GamePuzzleNames.puzzle)
                setPuzzle(data.GamePuzzleNames.puzzle);
              if(data.GamePuzzleNames.assessment)
                setAssesment(data.GamePuzzleNames.assessment);
            }
          } else {
            allSectionAnalyticsData[section] = data;
          }
          if (Object.keys(analyticsData).length === selectedSections.current.length) {
            if (moduleNotAssignedToSectionsCount === selectedSections.current.length) {
              let misMatched = false;
              Object.keys(analyticsData).map((sec) => {
                if (!props.gradeData[grade.current].includes(sec)) {
                  misMatched = true;
                }
              });
              if (!misMatched && selectedModule === moduleId.current) {
                setModuleNotAssigned(true);
                setisLoader(false);
              }
            } else {
              setModuleNotAssigned(false);
              createGraphsData();
            }
          }
          if (Object.keys(allSectionAnalyticsData).length === props.gradeData[props.grade].length) {
            setAllSectionData(allSectionAnalyticsData);
            if(!currentModuleAllSectionData[selectedModuleType]) {
              currentModuleAllSectionData[selectedModuleType] = {};
            }
            currentModuleAllSectionData[selectedModuleType][selectedModule] = allSectionAnalyticsData;
            setModuleWiseAllSectionData((currentValue) => {
              if (currentValue) {
                if(!currentValue[selectedModuleType]) {
                  currentValue[selectedModuleType] = {};
                }
                currentValue[selectedModuleType][selectedModule] = allSectionAnalyticsData;
                return currentValue;
              }
              return currentModuleAllSectionData;
            });
            setGradeWiseData((currentValue) => {
              if (currentValue) {
                if (currentValue[props.grade]) {
                  if(!currentValue[props.grade][selectedModuleType]) {
                    currentValue[props.grade][selectedModuleType]={};
                  }
                  currentValue[props.grade][selectedModuleType][selectedModule] = allSectionAnalyticsData;
                  return currentValue;
                }
                currentValue[props.grade] = {};
                currentValue[props.grade][selectedModuleType] = {};
                currentValue[props.grade][selectedModuleType][selectedModule] = allSectionAnalyticsData;
                return currentValue;
              }
              const newValue = {};
              newValue[props.grade] = {};
              newValue[props.grade][selectedModuleType] = {};
              newValue[props.grade][selectedModuleType][selectedModule] = allSectionAnalyticsData;
              return newValue;
            });
            const module_analytics_data = JSON.parse(sessionStorage.getItem('module_analytics_data'));
            if (!module_analytics_data.hasOwnProperty(schoolcode)) {
              module_analytics_data[schoolcode] = {};
            }
            if (module_analytics_data[schoolcode][props.grade]) {
              if (!module_analytics_data[schoolcode][props.grade][selectedModuleType]) {
                module_analytics_data[schoolcode][props.grade][selectedModuleType] = {};
              }
              module_analytics_data[schoolcode][props.grade][selectedModuleType][selectedModule] = allSectionAnalyticsData;
            } else {
              module_analytics_data[schoolcode][props.grade] = {};
              module_analytics_data[schoolcode][props.grade][selectedModuleType] = {};
              module_analytics_data[schoolcode][props.grade][selectedModuleType][selectedModule] = allSectionAnalyticsData;
            }
            sessionStorage.setItem('module_analytics_data', JSON.stringify(module_analytics_data));
          }
        });
      }
    }
  }, [moduleRefresh]);

  const [sectionsRefresh, setSectionsRefresh] = useState(true);
  useEffect(() => {
    selectedSections.current = props.selectedSections;
    setModuleNotAssigned();
    setGameLevelData({});
    setGamePerformanceData({});
    setPythonLevelData({});
    setPythonPerformanceData({});
    setPuzzleLevelProgressData({});
    setPuzzlePerformanceData({});
    setAssesmentProgressData({});
    setAssesmentGamePerformanceData({});
    setAssesmentPythonPerformanceData({});
    setAssesmentPuzzlePerformanceData({});
    setPuzzlePieLegend(false);
    setAssesmentPieLegend(false);
    setisLoader(true);
    setisLoading(true);
    setSectionsRefresh((prev) => !prev);
  }, [props.selectedSections]);

  useEffect(() => {
    analyticsData = {};
    if (selectedModule && isModuleFetched) {
      let moduleNotAssignedToSectionsCount = 0;
      if (gradeWiseData && gradeWiseData[props.grade]) {
        const modulesNames = [...gradeWiseData[props.grade].moduleNames.modules,...gradeWiseData[props.grade].moduleNames.notebooks];
        const assignmentData = gradeWiseData[props.grade].moduleAssignment;
        const sectionAssignmentData = new Array(modulesNames.length).fill('notassigned');
        sectionAssignmentData.map((value, index) => {
          let assignedCount = 0;
          selectedSections.current.map((sec) => {
            if (assignmentData[sec][index] === 'assigned') assignedCount++;
          });
          if (assignedCount === selectedSections.current.length) sectionAssignmentData[index] = 'assigned';
        });
        setModuleAssignmentData(sectionAssignmentData);
      }
      if (allSectionData) {
        const modulesNames = [...gradeWiseData[props.grade].moduleNames.modules,...gradeWiseData[props.grade].moduleNames.notebooks];
        const assignmentData = gradeWiseData[props.grade].moduleAssignment;
        const sectionAssignmentData = new Array(modulesNames.length).fill('notassigned');
        sectionAssignmentData.map((value, index) => {
          let assignedCount = 0;
          selectedSections.current.map((sec) => {
            if (assignmentData[sec][index] === 'assigned') assignedCount++;
          });
          if (assignedCount === selectedSections.current.length) sectionAssignmentData[index] = 'assigned';
        });
        setModuleAssignmentData(sectionAssignmentData);
        Object.keys(allSectionData).map((section) => {
          if (selectedSections.current.includes(section)) {
            const data = allSectionData[section];
            analyticsData[section] = data;
            if (data.status === 'notAssigned') moduleNotAssignedToSectionsCount++;
            else {
              if(data.GamePuzzleNames.game) setGame(data.GamePuzzleNames.game);
              if(data.GamePuzzleNames.python) setPython(data.GamePuzzleNames.python);
              if(data.GamePuzzleNames.puzzle) setPuzzle(data.GamePuzzleNames.puzzle);
              if(data.GamePuzzleNames.assessment) setAssesment(data.GamePuzzleNames.assessment);
            }
          }
        });
        if (Object.keys(analyticsData).length === selectedSections.current.length) {
          if (moduleNotAssignedToSectionsCount === selectedSections.current.length) {
            setModuleNotAssigned(true);
            setisLoader(false);
          } else {
            setModuleNotAssigned(false);
            createGraphsData();
          }
        }
      }
    }
  }, [sectionsRefresh]);

  const createGraphsData = () => {
    if (Object.keys(analyticsData).length === selectedSections.current.length) {
      const GameLevelData = { reRender: reRenderGraphs, grade: props.grade };
      const GamePerformanceData = { reRender: reRenderGraphs, grade: props.grade };
      const PythonLevelData = { reRender: reRenderGraphs, grade: props.grade };
      const PythonPerformanceData = { reRender: reRenderGraphs, grade: props.grade };
      const PuzzleLevelProgressData = { reRender: reRenderGraphs, grade: props.grade };
      const PuzzlePerformanceData = { reRender: reRenderGraphs, grade: props.grade };
      const AssesmentProgressData = { reRender: reRenderGraphs, grade: props.grade };
      const AssesmentGamePerformanceData = { reRender: reRenderGraphs, grade: props.grade };
      const AssesmentPythonPerformanceData = { reRender: reRenderGraphs, grade: props.grade };
      const AssesmentPuzzlePerformanceData = { reRender: reRenderGraphs, grade: props.grade };
      let foundFirstAssignedModule = false;
      Object.keys(analyticsData).map((section, index) => {
        if (analyticsData[section].status !== 'notAssigned') {
          if (!foundFirstAssignedModule) {
            // DeepClone
            const copiedFirstSection = JSON.parse(JSON.stringify(analyticsData[section]));
            if(copiedFirstSection.GameLevelData && copiedFirstSection.GamePerformanceData) {
              GameLevelData.Completed = copiedFirstSection.GameLevelData.Completed;
              GameLevelData.Incomplete = copiedFirstSection.GameLevelData.Incomplete;
              GameLevelData.categories = copiedFirstSection.GameLevelData.categories;
              GameLevelData.numberofStudents = copiedFirstSection.GameLevelData.numberofStudents;
              GamePerformanceData.medianAttempts = copiedFirstSection.GamePerformanceData.medianAttempts;
              GamePerformanceData.categories = copiedFirstSection.GamePerformanceData.categories;
              GamePerformanceData.numberofStudents = copiedFirstSection.GamePerformanceData.numberofStudents;
            }

            if (copiedFirstSection.PythonLevelData && copiedFirstSection.PythonPerformanceData) {
              PythonLevelData.Completed = copiedFirstSection.PythonLevelData.Completed;
              PythonLevelData.Incomplete = copiedFirstSection.PythonLevelData.Incomplete;
              PythonLevelData.categories = copiedFirstSection.PythonLevelData.categories;
              PythonLevelData.numberofStudents = copiedFirstSection.PythonLevelData.numberofStudents;

              PythonPerformanceData.medianAttempts = copiedFirstSection.PythonPerformanceData.medianAttempts;
              PythonPerformanceData.categories = copiedFirstSection.PythonPerformanceData.categories;
              PythonPerformanceData.numberofStudents = copiedFirstSection.PythonPerformanceData.numberofStudents;
            }
            if(copiedFirstSection.PuzzleLevelProgressData && copiedFirstSection.PuzzlePerformanceData) {
              PuzzleLevelProgressData.Incomplete = copiedFirstSection.PuzzleLevelProgressData.Incomplete;
              PuzzleLevelProgressData.Completed = copiedFirstSection.PuzzleLevelProgressData.Completed;
              PuzzleLevelProgressData.categories = copiedFirstSection.PuzzleLevelProgressData.categories;
              PuzzleLevelProgressData.numberofStudents = copiedFirstSection.PuzzleLevelProgressData.numberofStudents;

              PuzzlePerformanceData.levelsData = copiedFirstSection.PuzzlePerformanceData.levelsData;
              PuzzlePerformanceData.correctOpt = copiedFirstSection.PuzzlePerformanceData.correctOpt;
            }
            if(copiedFirstSection.AssesmentProgressData) {
              AssesmentProgressData.Completed = copiedFirstSection.AssesmentProgressData.Completed;
              AssesmentProgressData.Incomplete = copiedFirstSection.AssesmentProgressData.Incomplete;
              AssesmentProgressData.Gaveup = copiedFirstSection.AssesmentProgressData.Gaveup;
              AssesmentProgressData.categories = copiedFirstSection.AssesmentProgressData.categories;
              AssesmentProgressData.numberofStudents = copiedFirstSection.AssesmentProgressData.numberofStudents;
              if(copiedFirstSection.AssesmentGamePerformanceData) {
                AssesmentGamePerformanceData.completedAttempts = copiedFirstSection.AssesmentGamePerformanceData.completedAttempts;
                AssesmentGamePerformanceData.gaveupAttempts = copiedFirstSection.AssesmentGamePerformanceData.gaveupAttempts;
                AssesmentGamePerformanceData.categories = copiedFirstSection.AssesmentGamePerformanceData.categories;
                AssesmentGamePerformanceData.numberofStudents = copiedFirstSection.AssesmentGamePerformanceData.numberofStudents;
              }

              if( copiedFirstSection.AssesmentPythonPerformanceData) {
                AssesmentPythonPerformanceData.completedAttempts = copiedFirstSection.AssesmentPythonPerformanceData.completedAttempts;
                AssesmentPythonPerformanceData.gaveupAttempts = copiedFirstSection.AssesmentPythonPerformanceData.gaveupAttempts;
                AssesmentPythonPerformanceData.categories = copiedFirstSection.AssesmentPythonPerformanceData.categories;
                AssesmentPythonPerformanceData.numberofStudents = copiedFirstSection.AssesmentPythonPerformanceData.numberofStudents;
              }
              if( copiedFirstSection.AssesmentPuzzlePerformanceData) {
                AssesmentPuzzlePerformanceData.levelsData = copiedFirstSection.AssesmentPuzzlePerformanceData.levelsData;
                AssesmentPuzzlePerformanceData.correctOpt = copiedFirstSection.AssesmentPuzzlePerformanceData.correctOpt;
              }
            }
            foundFirstAssignedModule = true;
          } else {
            if(analyticsData[section].GameLevelData && analyticsData[section].GamePerformanceData) {
              analyticsData[section].GameLevelData?.Completed?.map((value, index) => {
                GameLevelData.Completed[index] += value;
              });
              analyticsData[section].GameLevelData.Incomplete.map((value, index) => {
                GameLevelData.Incomplete[index] += value;
              });
              GameLevelData.numberofStudents += analyticsData[section].GameLevelData.numberofStudents;

              analyticsData[section].GamePerformanceData.medianAttempts.map((value, index) => {
                GamePerformanceData.medianAttempts[index] = GamePerformanceData.medianAttempts[index].concat(value);
              });
            }
            if(analyticsData[section].PythonLevelData && analyticsData[section].PythonPerformanceData) {
              analyticsData[section].PythonLevelData.Completed.map((value, index) => {
                PythonLevelData.Completed[index] += value;
              });
              analyticsData[section].PythonLevelData.Incomplete.map((value, index) => {
                PythonLevelData.Incomplete[index] += value;
              });
              PythonLevelData.numberofStudents += analyticsData[section].PythonLevelData.numberofStudents;

              analyticsData[section].PythonPerformanceData.medianAttempts.map((value, index) => {
                PythonPerformanceData.medianAttempts[index] = PythonPerformanceData.medianAttempts[index].concat(value);
              });
            }

            if(analyticsData[section].PuzzleLevelProgressData && analyticsData[section].PuzzlePerformanceData) {
              analyticsData[section].PuzzleLevelProgressData.Incomplete.map((value, index) => {
                PuzzleLevelProgressData.Incomplete[index] += value;
              });
              analyticsData[section].PuzzleLevelProgressData.Completed.map((value, index) => {
                PuzzleLevelProgressData.Completed[index] += value;
              });
              PuzzleLevelProgressData.numberofStudents += analyticsData[section].PuzzleLevelProgressData.numberofStudents;

              Object.keys(analyticsData[section].PuzzlePerformanceData.levelsData).map((key) => {
                analyticsData[section].PuzzlePerformanceData.levelsData[key].map((value, index) => {
                  PuzzlePerformanceData.levelsData[key][index] += value;
                });
              });
            }
            if(analyticsData[section].AssesmentProgressData) {
              analyticsData[section].AssesmentProgressData.Completed.map((value, index) => {
                AssesmentProgressData.Completed[index] += value;
              });
              analyticsData[section].AssesmentProgressData.Incomplete.map((value, index) => {
                AssesmentProgressData.Incomplete[index] += value;
              });
              analyticsData[section].AssesmentProgressData.Gaveup.map((value, index) => {
                AssesmentProgressData.Gaveup[index] += value;
              });
              AssesmentProgressData.numberofStudents += analyticsData[section].AssesmentProgressData.numberofStudents;
              if(analyticsData[section].AssesmentGamePerformanceData){
                analyticsData[section].AssesmentGamePerformanceData.completedAttempts.map((value, index) => {
                  AssesmentGamePerformanceData.completedAttempts[index] = AssesmentGamePerformanceData.completedAttempts[index].concat(value);
                });
                analyticsData[section].AssesmentGamePerformanceData.gaveupAttempts.map((value, index) => {
                  AssesmentGamePerformanceData.gaveupAttempts[index] = AssesmentGamePerformanceData.gaveupAttempts[index].concat(value);
                });
              }
              if(analyticsData[section].AssesmentPythonPerformanceData){
                analyticsData[section].AssesmentPythonPerformanceData.completedAttempts.map((value, index) => {
                  AssesmentPythonPerformanceData.completedAttempts[index] = AssesmentPythonPerformanceData.completedAttempts[index].concat(value);
                });
                analyticsData[section].AssesmentPythonPerformanceData.gaveupAttempts.map((value, index) => {
                  AssesmentPythonPerformanceData.gaveupAttempts[index] = AssesmentPythonPerformanceData.gaveupAttempts[index].concat(value);
                });
              }
              if(analyticsData[section].AssesmentPuzzlePerformanceData){
                Object.keys(analyticsData[section].AssesmentPuzzlePerformanceData.levelsData).map((key) => {
                  analyticsData[section].AssesmentPuzzlePerformanceData.levelsData[key].map((value, index) => {
                    AssesmentPuzzlePerformanceData.levelsData[key][index] += value;
                  });
                });
              }
            }
          }
        }
      });
      function median(values) {
        if (values.length === 0) return 0;

        values.sort((a, b) => a - b);

        const half = Math.floor(values.length / 2);

        if (values.length % 2) return values[half];

        return (values[half - 1] + values[half]) / 2.0;
      }
      if(GamePerformanceData.medianAttempts){
        GamePerformanceData.medianAttempts.map((value, index) => {
          GamePerformanceData.medianAttempts[index] = median(value);
        });
      }

      if(PythonPerformanceData.medianAttempts){
        PythonPerformanceData.medianAttempts.map((value, index) => {
          PythonPerformanceData.medianAttempts[index] = median(value);
        });
      }
      if(AssesmentGamePerformanceData.completedAttempts){
        AssesmentGamePerformanceData.completedAttempts.map((value, index) => {
          AssesmentGamePerformanceData.completedAttempts[index] = median(value);
        });
      }
      if(AssesmentGamePerformanceData.gaveupAttempts){
        AssesmentGamePerformanceData.gaveupAttempts.map((value, index) => {
          AssesmentGamePerformanceData.gaveupAttempts[index] = median(value);
        });
      }

      if(AssesmentPythonPerformanceData.completedAttempts){
        AssesmentPythonPerformanceData.completedAttempts.map((value, index) => {
          AssesmentPythonPerformanceData.completedAttempts[index] = median(value);
        });
      }
      if(AssesmentPythonPerformanceData.gaveupAttempts){
        AssesmentPythonPerformanceData.gaveupAttempts.map((value, index) => {
          AssesmentPythonPerformanceData.gaveupAttempts[index] = median(value);
        });
      }
      let isData = false;
      if(PuzzlePerformanceData?.levelsData){
        Object.keys(PuzzlePerformanceData.levelsData).map((key) => {
          PuzzlePerformanceData.levelsData[key].map((val) => {
            if (val > 0) isData = true;
          });
        });
        setPuzzlePieLegend(isData);
      }
      isData = false;
      if(AssesmentPuzzlePerformanceData?.levelsData){
        Object.keys(AssesmentPuzzlePerformanceData.levelsData).map((key) => {
          AssesmentPuzzlePerformanceData.levelsData[key]?.map((val) => {
            if (val > 0) isData = true;
          });
        });
      }
      let exitFunction = false;
      Object.keys(analyticsData).map((sec) => {
        if (!props.gradeData[grade.current].includes(sec)) {
          exitFunction = true;
        }
      });
      if (exitFunction) {

      } else if (moduleId.current === selectedModule) {
        setAssesmentPieLegend(isData);
        setGameLevelData(GameLevelData);
        setGamePerformanceData(GamePerformanceData);
        setPythonLevelData(PythonLevelData);
        setPythonPerformanceData(PythonPerformanceData);
        setPuzzleLevelProgressData(PuzzleLevelProgressData);
        setPuzzlePerformanceData(PuzzlePerformanceData);
        setAssesmentProgressData(AssesmentProgressData);
        setAssesmentGamePerformanceData(AssesmentGamePerformanceData);
        setAssesmentPythonPerformanceData(AssesmentPythonPerformanceData);
        setAssesmentPuzzlePerformanceData(AssesmentPuzzlePerformanceData);
        setisLoader(false);
      }
    }
  };

  const handleModuleChange = (moduleIndex, e) => {
    setSelectedModuleId(moduleIndex);
    setSelectedModuleType(gradeWiseData[props.grade].moduleNames.modules.length > moduleIndex ? 'module' : 'notebook')
    moduleId.current = modules[moduleIndex][1];
    setSelectedModule(modules[moduleIndex][1]);
  };

  return (
    isLoading
      ? (
        <div>
          {selectedModule && modules && (
          <Box sx={{ width: '100%', typography: 'body1' }} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="container" style={{ marginTop: 10 }}>
              <div className="arrow-steps" style={{ display: 'flex' }}>
                <div className="step" style={{ Width: '10%' }}>
                  {' '}
                  <span>Modules</span>
                  {' '}
                </div>
                {modules.map((key, index) => (
                  <div
                    key={index + 1} id={index} className={`step ${selectedModuleId === index ? moduleAssignmentData[index] === 'assigned' ? 'currentAssigned' : 'currentNotAssigned' : ''} ${
              moduleAssignmentData[index] === 'assigned' ? 'assigned' : ''
            }`} onClick={(e) => handleModuleChange(index, e)} style={{ cursor: 'Pointer', minWidth: `${moduleBarWidth}%` }}
                  > 
                    {' '}
                    <span>{index + 1}</span>
                    {' '}

                  </div>
                ))}
                <div className="step" style={{ width: '2%' }}>
                  {' '}
                  <span />
                  {' '}
                </div>
              </div>
            </div>
          </Box>
          )}
          {!isLoader
            ? (
              <div>
                {moduleNotAssigned && (
                <Typography
                  variant="subtitle1"
                  style={{
                    width: 'fit-content', marginLeft: 'auto', marginRight: 'auto', fontFamily: 'rajdhani-medium', fontSize: 20, marginTop: 30,
                  }}
                  >
                  Module is Not Assigned Yet
                </Typography>
                )}
                {!moduleNotAssigned && (
                <Container style={{ marginTop: 30, padding: 0 }}>
                  <Typography variant="h6" component="h2" style={{ fontFamily: 'rajdhani-medium' }}>
                    <span style={{ fontSize: 20 }}>
                      MODULE {selectedModuleId + 1}:
                    </span>
                    {' '}
                    <span style={{ fontSize: 30 }}>{modules[selectedModuleId][0]}</span>
                  </Typography>
                  { Object.keys(gameLevelData).length !== 0  && gameLevelData.categories?
                    (<Box style={{
                    backgroundColor: '#F4F4F4', paddingTop: 10, borderRadius: 10, marginTop: 10, paddingLeft: 30, paddingRight: 30,
                  }}
                  >
                    <Typography variant="h6" component="h2" marginTop={2}>
                      <span style={{ fontSize: 25, fontFamily: 'rajdhani-semibold' }}>GAME: </span>
                      {' '}
                      <span style={{ fontFamily: 'rajdhani-medium', fontSize: 20 }}>{game}</span>
                    </Typography>
                    <Grid container spacing={15} style={{ padding: 40 }}>
                      <Grid item xs={6}>
                        {Object.keys(gameLevelData).length !== 0 && <GameLevelProgress data={gameLevelData} grade={props.grade} />}
                      </Grid>
                      <Grid item xs={6}>
                        {Object.keys(gamePerformanceData).length !== 0 && <GamePerformance data={gamePerformanceData} grade={props.grade} />}
                      </Grid>
                    </Grid>
                    </Box>):
                    (<></>)
                  }
                  { Object.keys(pythonLevelData).length !== 0 && pythonLevelData?.categories?.length > 0 ? 
                    ( <Box style={{
                          backgroundColor: '#F4F4F4', paddingTop: 10, borderRadius: 10, marginTop: 10, paddingLeft: 30, paddingRight: 30,
                        }}
                      >
                      <Typography variant="h6" component="h2" marginTop={2}>
                        <span style={{ fontSize: 25, fontFamily: 'rajdhani-semibold' }}>PYTHON: </span>
                        {' '}
                        <span style={{ fontFamily: 'rajdhani-medium', fontSize: 20 }}>{python?.python_set_name}</span>
                      </Typography>
                      <Grid container spacing={15} style={{ padding: 40 }}>
                        <Grid item xs={6}>
                          <Grid item>
                            {Object.keys(pythonLevelData).length !== 0 && pythonLevelData?.categories && <PythonLevelProgress data={pythonLevelData} grade={props.grade} />}
                          </Grid>
                          <Grid item style={{ display: 'flex', flexDirection: 'column', marginTop: 25 }}>
                            {python?.python_levels_title?.map((element, index) => (
                              <Typography variant="subtitle" style={{ fontSize: 20 }} key={element}>
                                <span style={{ fontFamily: 'rajdhani-semibold' }}>
                                  Py{index + 1}
                                  :
                                  {' '} 
                                </span>
                                <span style={{ fontFamily: 'rajdhani-medium' }}>{element}</span>
                              </Typography>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          {Object.keys(pythonPerformanceData).length !== 0 && pythonPerformanceData?.categories && <PythonPerformance data={pythonPerformanceData} grade={props.grade} />}
                        </Grid>
                      </Grid>
                    </Box>
                    ) :
                    (<></>) 
                  }
                  { Object.keys(puzzleLevelProgressData).length !== 0 && puzzleLevelProgressData?.categories ?
                    (<Box
                    style={{
                      backgroundColor: "#F4F4F4",
                      paddingTop: 10,
                      borderRadius: 10,
                      marginTop: 10,
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                  >
                    <Typography
                      variant='h6'
                      component='h2'
                      marginTop={2}
                      style={{ fontFamily: "rajdhani-semibold", fontSize: 25 }}
                    >
                      PUZZLE
                    </Typography>
                    <Grid container spacing={15} style={{ padding: 40 }}>
                      <Grid item xs={6}>
                        <Grid item>
                          {Object.keys(puzzleLevelProgressData).length !== 0 && (
                            <PuzzleLevelProgress
                              data={puzzleLevelProgressData}
                              grade={props.grade}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          style={{ display: "flex", flexDirection: "column", marginTop: 25 }}
                        >
                          {puzzle?.map((element, index) => (
                            <Typography variant='subtitle' style={{ fontSize: 20 }} key={element}>
                              <span style={{ fontFamily: "rajdhani-semibold" }}>
                                P{index + 1}:{" "}
                              </span>
                              <span style={{ fontFamily: "rajdhani-medium" }}>{element}</span>
                            </Typography>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          component='h2'
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: 1,
                          }}
                        >
                          <span style={{ fontFamily: "rajdhani-semibold", fontSize: 22 }}>
                            Puzzle Performance
                          </span>
                          <span
                            style={{
                              fontFamily: "rajdhani-medium",
                              fontSize: 15,
                              marginTop: "-10px",
                            }}
                          >
                            (of the students who have completed)
                          </span>
                        </Typography>
                        <Grid
                          container
                          style={{ display: "flex", width: "100%", justifyContent: "center" }}
                        >
                          {puzzlePieLegend ? (
                            Object.keys(puzzlePerformanceData).length !== 0 &&
                            Object.keys(puzzlePerformanceData.levelsData).map((key, index) => (
                              <Box style={{ width: "50%", padding: 0, marginBottom: 20 }}>
                                <PuzzlePerformance
                                  data={puzzlePerformanceData.levelsData[key]}
                                  index={index}
                                  correctOpt={puzzlePerformanceData.correctOpt[index]}
                                  grade={props.grade}
                                  reRenderData={{
                                    reRender: puzzlePerformanceData.reRender,
                                    grade: puzzlePerformanceData.grade,
                                  }}
                                />
                              </Box>
                            ))
                          ) : (
                            <Typography
                              variant='subtitle1'
                              style={{
                                width: "fit-content",
                                fontFamily: "rajdhani-medium",
                                fontSize: 20,
                                height: 450,
                                marginTop: "40%",
                              }}
                            >
                              Puzzle: No Data Available
                            </Typography>
                          )}
                          {puzzlePieLegend && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "end",
                                paddingRight: 25,
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <span
                                  style={{
                                    height: 15,
                                    width: 15,
                                    backgroundColor: "#D1D526",
                                    borderRadius: "50%",
                                  }}
                                />
                                <Typography
                                  style={{
                                    paddingLeft: 6,
                                    color: "#484C51",
                                    fontFamily: "rajdhani-medium",
                                    fontSize: 15,
                                  }}
                                >
                                  Correct
                                </Typography>
                              </div>
                              <div
                                style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}
                              >
                                <span
                                  style={{
                                    height: 15,
                                    width: 15,
                                    backgroundColor: "#FD9E63",
                                    borderRadius: "50%",
                                  }}
                                />
                                <span
                                  style={{
                                    height: 15,
                                    width: 15,
                                    backgroundColor: "#F7B09C",
                                    borderRadius: "50%",
                                    marginLeft: 3,
                                  }}
                                />
                                <span
                                  style={{
                                    height: 15,
                                    width: 15,
                                    backgroundColor: "#FF8C77",
                                    borderRadius: "50%",
                                    marginLeft: 3,
                                  }}
                                />
                                <Typography
                                  style={{
                                    paddingLeft: 6,
                                    color: "#484C51",
                                    fontFamily: "rajdhani-medium",
                                    fontSize: 15,
                                  }}
                                >
                                  Incorrect
                                </Typography>
                              </div>
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    </Box>) : 
                    (<></>)
                  }
                  { Object.keys(assesmentProgressData).length > 2 ?
                    (<Box
                    style={{
                      backgroundColor: "#F4F4F4",
                      paddingTop: 10,
                      borderRadius: 10,
                      marginTop: 10,
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                  >
                    <Typography
                      variant='h6'
                      component='h2'
                      marginTop={2}
                      style={{ fontFamily: "rajdhani-semibold", fontSize: 25 }}
                    >
                      ASSESSMENT
                    </Typography>
                    <Grid container spacing={15} style={{ padding: 40 }}>
                        {Object.keys(assesmentProgressData).length >2 ? (
                            <Grid item xs={6}>
                                <AssesmentProgress data={assesmentProgressData} grade={props.grade} />
                            </Grid>
                          ): (
                            <></>
                          )
                        }
                        {assesment?.game ? (
                          <Grid item xs={6}>
                              <AssesmentGamePythonPerformance
                                data={assesmentGamePerformanceData}
                                grade={props.grade}
                                type="Game"
                              />
                          </Grid>
                        ):( assesment?.python ? (
                          <Grid item xs={6}>
                              <AssesmentGamePythonPerformance
                                data={assesmentPythonPerformanceData}
                                grade={props.grade}
                                type="Python"
                              />
                          </Grid>
                        ) : (
                          <Grid
                            container
                            item xs={6}
                            style={{
                              display: "flex",
                              width: "50%",
                              justifyContent: "center",
                              paddingLeft: 40,
                              paddingRight: 40,
                            }}
                          >
                            <Typography
                              component='h2'
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                                marginBottom: 2,
                                fontFamily: "rajdhani-semibold",
                                fontSize: 22,
                              }}
                            >
                              Puzzle Performance
                            </Typography>
                            {assesmentPieLegend ? (
                              assesment?.puzzle &&
                              Object.keys(assesmentPuzzlePerformanceData.levelsData).map(
                                (key, index) => (
                                  <Box style={{ width: "50%", padding: 0, marginBottom: 20 }}>
                                    <AssesmentPuzzlePerformance
                                      data={assesmentPuzzlePerformanceData.levelsData[key]}
                                      index={index}
                                      correctOpt={assesmentPuzzlePerformanceData.correctOpt[index]}
                                      grade={props.grade}
                                      reRenderData={{
                                        reRender: assesmentPuzzlePerformanceData.reRender,
                                        grade: assesmentPuzzlePerformanceData.grade,
                                      }}
                                    />
                                  </Box>
                                )
                              )
                            ) : (
                              <Typography
                                variant='subtitle1'
                                style={{
                                  width: "fit-content",
                                  fontFamily: "rajdhani-medium",
                                  fontSize: 20,
                                  marginTop: 45,
                                  height: 100,
                                }}
                              >
                                Puzzle: No Data Available
                              </Typography>
                            )}
                            {assesmentPieLegend && (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingBottom: 20,
                                  paddingRight: 25,
                                }}
                              >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <span
                                    style={{
                                      height: 15,
                                      width: 15,
                                      backgroundColor: "#D1D526",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      paddingLeft: 6,
                                      color: "#484C51",
                                      fontFamily: "rajdhani-medium",
                                      fontSize: 15,
                                    }}
                                  >
                                    Correct
                                  </Typography>
                                </div>
                                <div
                                  style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}
                                >
                                  <span
                                    style={{
                                      height: 15,
                                      width: 15,
                                      backgroundColor: "#FD9E63",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <span
                                    style={{
                                      height: 15,
                                      width: 15,
                                      backgroundColor: "#F7B09C",
                                      borderRadius: "50%",
                                      marginLeft: 3,
                                    }}
                                  />
                                  <span
                                    style={{
                                      height: 15,
                                      width: 15,
                                      backgroundColor: "#FF8C77",
                                      borderRadius: "50%",
                                      marginLeft: 3,
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      paddingLeft: 6,
                                      color: "#484C51",
                                      fontFamily: "rajdhani-medium",
                                      fontSize: 15,
                                    }}
                                  >
                                    Incorrect
                                  </Typography>
                                </div>
                                <div
                                  style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}
                                >
                                  <span
                                    style={{
                                      height: 15,
                                      width: 15,
                                      backgroundColor: "#B30A74  ",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Typography
                                    style={{
                                      paddingLeft: 6,
                                      color: "#484C51",
                                      fontFamily: "rajdhani-medium",
                                      fontSize: 15,
                                    }}
                                  >
                                    Needed Help
                                  </Typography>
                                </div>
                              </div>
                            )}
                          </Grid>
                        )
                        )}
                    </Grid>
                    <Grid container>
                      { (!assesment.game || !assesment.puzzle || !assesment.python)
                      ?  <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          
                          padding: assesment?.game && assesment.puzzle ? "55px" : "0 40px 40px",
                        }}
                      >
                        {assesment?.game ? <Typography variant='subtitle' style={{ fontSize: 20 }}>
                          <span style={{ fontFamily: "rajdhani-semibold" }}>Game: </span>
                          <span style={{ fontFamily: "rajdhani-medium" }}>{assesment?.game}</span>
                        </Typography> :
                        <></>
                        }
                        {assesment?.python ? <Typography variant='subtitle' style={{ fontSize: 20 }}>
                            <span style={{ fontFamily: "rajdhani-semibold" }}>Python: </span>
                            <span style={{ fontFamily: "rajdhani-medium" }}>{assesment?.python}</span>
                            </Typography> :
                            <></>
                        }
                        {assesment?.puzzle?.map((element, index) => (
                          <Typography variant='subtitle' style={{ fontSize: 20 }} key={element}>
                            <span style={{ fontFamily: "rajdhani-semibold" }}>
                              P{index + 1}:{" "}
                            </span>
                            <span style={{ fontFamily: "rajdhani-medium" }}>{element}</span>
                          </Typography>
                        ))}
                      </Grid>: <></>
                      }
                      { assesment?.game &&  assesment?.python ? (
                          <Grid item xs={6}>
                              <AssesmentGamePythonPerformance
                                data={assesmentPythonPerformanceData}
                                grade={props.grade}
                                type="Python"
                              />
                          </Grid>
                        ):<></>
                        }
                      {(assesment?.game ||  assesment?.python) && assesment?.puzzle ?
                      <Grid
                        container
                        style={{
                          display: "flex",
                          width: "50%",
                          justifyContent: "center",
                          paddingLeft: 40,
                          paddingRight: 40,
                        }}
                      >
                        <Typography
                          component='h2'
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                            marginBottom: 2,
                            fontFamily: "rajdhani-semibold",
                            fontSize: 22,
                          }}
                        >
                          Puzzle Performance
                        </Typography>
                        {assesmentPieLegend ? (
                          assesment.puzzle &&
                          Object.keys(assesmentPuzzlePerformanceData.levelsData).map(
                            (key, index) => (
                              <Box style={{ width: "50%", padding: 0, marginBottom: 20 }}>
                                <AssesmentPuzzlePerformance
                                  data={assesmentPuzzlePerformanceData.levelsData[key]}
                                  index={index}
                                  correctOpt={assesmentPuzzlePerformanceData.correctOpt[index]}
                                  grade={props.grade}
                                  reRenderData={{
                                    reRender: assesmentPuzzlePerformanceData.reRender,
                                    grade: assesmentPuzzlePerformanceData.grade,
                                  }}
                                />
                              </Box>
                            )
                          )
                        ) : (
                          <Typography
                            variant='subtitle1'
                            style={{
                              width: "fit-content",
                              fontFamily: "rajdhani-medium",
                              fontSize: 20,
                              marginTop: 45,
                              height: 100,
                            }}
                          >
                            Puzzle: No Data Available
                          </Typography>
                        )}
                        {assesmentPieLegend && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                              paddingBottom: 20,
                              paddingRight: 25,
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <span
                                style={{
                                  height: 15,
                                  width: 15,
                                  backgroundColor: "#D1D526",
                                  borderRadius: "50%",
                                }}
                              />
                              <Typography
                                style={{
                                  paddingLeft: 6,
                                  color: "#484C51",
                                  fontFamily: "rajdhani-medium",
                                  fontSize: 15,
                                }}
                              >
                                Correct
                              </Typography>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center", paddingLeft: 20 }}
                            >
                              <span
                                style={{
                                  height: 15,
                                  width: 15,
                                  backgroundColor: "#FD9E63",
                                  borderRadius: "50%",
                                }}
                              />
                              <span
                                style={{
                                  height: 15,
                                  width: 15,
                                  backgroundColor: "#F7B09C",
                                  borderRadius: "50%",
                                  marginLeft: 3,
                                }}
                              />
                              <span
                                style={{
                                  height: 15,
                                  width: 15,
                                  backgroundColor: "#FF8C77",
                                  borderRadius: "50%",
                                  marginLeft: 3,
                                }}
                              />
                              <Typography
                                style={{
                                  paddingLeft: 6,
                                  color: "#484C51",
                                  fontFamily: "rajdhani-medium",
                                  fontSize: 15,
                                }}
                              >
                                Incorrect
                              </Typography>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center", paddingLeft: 15 }}
                            >
                              <span
                                style={{
                                  height: 15,
                                  width: 15,
                                  backgroundColor: "#B30A74  ",
                                  borderRadius: "50%",
                                }}
                              />
                              <Typography
                                style={{
                                  paddingLeft: 6,
                                  color: "#484C51",
                                  fontFamily: "rajdhani-medium",
                                  fontSize: 15,
                                }}
                              >
                                Needed Help 
                              </Typography>
                            </div>
                          </div>
                        )}
                      </Grid> : <></>}
                    </Grid>
                    { (assesment?.game &&  assesment?.python && assesment?.puzzle)  
                      ? <Grid container>
                       <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: assesment?.game && assesment.puzzle ? "55px" : "0 40px 40px",
                        }}
                      >
                        {assesment?.game ? <Typography variant='subtitle' style={{ fontSize: 20 }}>
                          <span style={{ fontFamily: "rajdhani-semibold" }}>Game: </span>
                          <span style={{ fontFamily: "rajdhani-medium" }}>{assesment?.game}</span>
                        </Typography> :
                        <></>
                        }
                        {assesment?.python ? <Typography variant='subtitle' style={{ fontSize: 20 }}>
                            <span style={{ fontFamily: "rajdhani-semibold" }}>Python: </span>
                            <span style={{ fontFamily: "rajdhani-medium" }}>{assesment?.python}</span>
                            </Typography> :
                            <></>
                        }
                        {assesment?.puzzle?.map((element, index) => (
                          <Typography variant='subtitle' style={{ fontSize: 20 }} key={element}>
                            <span style={{ fontFamily: "rajdhani-semibold" }}>
                              P{index + 1}:{" "}
                            </span>
                            <span style={{ fontFamily: "rajdhani-medium" }}>{element}</span>
                          </Typography>
                        ))}
                      </Grid>
                      </Grid>: <></>
                      }
                    </Box>) :
                    (
                      <></>
                    )              
                  }
                </Container>
                )}
              </div>
            )
            : (
              <Box sx={{ display: 'flex', p: 5 }} justifyContent="center">
                <CircularProgress />
              </Box>
            )}
        </div>
      ) : (
        <Box sx={{ display: 'flex', p: 5 }} justifyContent="center">
          <CircularProgress />
        </Box>
      )
  );
}
