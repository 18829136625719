import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { downloadFile } from "../../features/FilesDownloader/FilesDownloader";
import { useContext } from "react";
import { authUserContext } from "../Contexts/AuthUser";
import PopupBox from "../Dialogs/PopupBox";
import DialogBoxTitle from "../Dialogs/DialogBoxTitle";
import {
  Checkbox,
  Button,
  FormControlLabel,
  FormGroup,
  DialogActions,
  FormLabel,
  RadioGroup,
  Radio,
  FormControl,
} from "@mui/material";

function TeacherReportsDownloadPopup(props) {
  const { grade, section, reportKey, setShowReportsDownloadPopup } = props;
  const { user } = useContext(authUserContext);
  const dispatch = useDispatch();
  const [selectedReportType, setSelectedReportType] = useState();
  const [reportSignedByTeacher, setReportSignedByTeacher] = useState("yes");

  const downloadStudentReport = () => {
    let reportType = "pdf";
    if (
      selectedReportType?.singleReport &&
      selectedReportType?.separateReports
    ) {
      reportType = "combined";
    } else if (selectedReportType?.singleReport) {
      reportType = "pdf";
    } else if (selectedReportType?.separateReports) {
      reportType = "zip";
    }
    const schoolCode = user.schoolcode;

    const fileDesc = {
      title: `Student Reports Grade ${grade} Section ${section}`,
      waitingMessage: "initializing...",
      url: `${process.env.REACT_APP_API_BASE_URL}/student-report/${grade}/${section}`,
      method: "POST",
      body: {
        reportType,
        signedByTeacher: reportSignedByTeacher === "yes" ? true : false,
      },
      filename:
        reportType === "pdf"
          ? `CSSoch_${schoolCode}_${grade}_${section}_all_student_reports.pdf`
          : `CSSoch_${schoolCode}_${grade}_${section}_student_reports.zip`,
      key: reportKey,
    };
    dispatch(downloadFile(fileDesc));
    setShowReportsDownloadPopup(false);
  };

  const handleClose = useCallback(() => {
    setShowReportsDownloadPopup(false);
  }, [setShowReportsDownloadPopup]);

  const handleReportTypeChange = useCallback((type, value) => {
    setSelectedReportType((prev) => {
      if (type === "singleReport") {
        const updateValue = { ...prev, singleReport: value };
        return updateValue;
      } else if (type === "separateReports") {
        const updateValue = { ...prev, separateReports: value };
        return updateValue;
      }
    });
  }, []);

  const handleSignedByTeacherChange = useCallback((e) => {
    setReportSignedByTeacher(e.target.value);
  }, []);

  return (
    <PopupBox open={true} handleClose={handleClose}>
      <DialogBoxTitle
        id="customized-dialog-title"
        onClose={handleClose}
        customTitleStyle={{ marginBottom: "1vh !important" }}
      >
        Download
      </DialogBoxTitle>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <FormGroup sx={{ fontSize: "2vw" }}>
          <FormControlLabel
            label="Download Student Report Cards (Single File)"
            control={
              <Checkbox
                checked={selectedReportType?.singleReport || false}
                onChange={(e) =>
                  handleReportTypeChange("singleReport", e.target.checked)
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: "1.7vw" } }}
              />
            }
            disableTypography={true}
          />
          <FormControlLabel
            label="Download Student Report Cards (Separate Files)"
            control={
              <Checkbox
                checked={selectedReportType?.separateReports || false}
                onChange={(e) =>
                  handleReportTypeChange("separateReports", e.target.checked)
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: "1.7vw" } }}
              />
            }
            disableTypography={true}
          />
        </FormGroup>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1rem",
        }}
      >
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: "2vw",
            fontFamily: "rajdhani-medium",
          }}
        >
          <FormLabel
            sx={{
              fontSize: "2vw",
              fontFamily: "rajdhani-medium",
              color: "rgba(0, 0, 0, 0.87) !important",
            }}
          >
            Teacher's Sign:
          </FormLabel>
          <RadioGroup
            defaultValue={"yes"}
            value={reportSignedByTeacher}
            name="signedByTeacher"
            sx={{ display: "flex", flexDirection: "row", marginLeft: "30px" }}
            onChange={handleSignedByTeacherChange}
          >
            <FormControlLabel
              value={"yes"}
              control={<Radio />}
              label="Yes"
              disableTypography={true}
              sx={{
                marginRight: "30px",
                "& .MuiRadio-root": { padding: "0 5px 0" },
              }}
            />
            <FormControlLabel
              value={"no"}
              control={<Radio />}
              label="No"
              disableTypography={true}
              sx={{
                "& .MuiRadio-root": { padding: "0 5px 0" },
              }}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <DialogActions>
        <Button
          style={{ width: "fit-content", margin: "0.5vw" }}
          onClick={downloadStudentReport}
          disabled={
            !selectedReportType?.singleReport &&
            !selectedReportType?.separateReports
          }
        >
          Download
        </Button>
        <Button
          style={{ width: "fit-content", margin: "0.5vw" }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </PopupBox>
  );
}

export default TeacherReportsDownloadPopup;
